import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { InsertUpdateOrderRequest } from '@app/shared/classes/messageContracts/request/insertUpdateOrderRequest';
import { InsertOrderPaymentDetailsRequest } from '@app/shared/classes/messageContracts/request/insertOrderPaymentDetailsRequest';
import { Order } from '@app/shared/classes/models/order';
import { OrderPaymentDetails } from '@app/shared/classes/models/orderPaymentDetails';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json'
  }),

  params: {}
};

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  apiUrl: string;
  //require: any;

  constructor(private http: HttpClient) { 
    
  }

  saveOrder(oOrder: Order){
    
    
    this.apiUrl = environment.apiUrl + 'Order/CreateOrder';
    let oInsertUpdateOrderRequest = new InsertUpdateOrderRequest();
    oInsertUpdateOrderRequest.order = oOrder;
    //console.log(oInsertUpdateOrderRequest);
    // var fs = require('fs');
    // fs.writeFile("/fileName.json", oInsertUpdateOrderRequest, function(err) {
    //   if(err) {
    //       return //console.log(err);
    //   }
  
    //   //console.log("The file was saved!");
    // }); 
    //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/Product/UpdateCart";
    return this.http.post<any>(this.apiUrl, oInsertUpdateOrderRequest);
  }

  getOrder(orderId, orderDetailId){
    
    this.apiUrl = environment.apiUrl + 'Order/GetOrder?OrderId=' + orderId + '&orderDetailId='+ orderDetailId;
    //this.apiUrl = environment.apiUrl + 'Order/GetOrder';
    //httpOptions.params = {OrderId : 0, UserId: UserID};
    //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/Order/GetOrder/6/79";
    return this.http.get<any>(this.apiUrl, httpOptions);
  }

  insertOrderPaymentDetails(oOrderPaymentDetails: OrderPaymentDetails){
    
    //debugger
    this.apiUrl = environment.apiUrl + 'Order/InsertOrderPaymentDetails';
    let oInsertOrderPaymentDetailsRequest = new InsertOrderPaymentDetailsRequest();
    oInsertOrderPaymentDetailsRequest.OrderPayment = oOrderPaymentDetails;
    //console.log(oInsertOrderPaymentDetailsRequest);
    return this.http.post<any>(this.apiUrl, oInsertOrderPaymentDetailsRequest);
  }

  
  getPaymentDetails(paymentId){
    
    this.apiUrl = environment.apiUrl + 'order/GetOrderPaymentDetails?orderPaymentId=' + paymentId + '&orderId=0';
    return this.http.get<any>(this.apiUrl, httpOptions);
  }

  applyCoupon(couponCode: string){
    this.apiUrl = environment.apiUrl + 'order/GetValidateAndGetCoupon?couponCode=' + couponCode;
    return this.http.get<any>(this.apiUrl, httpOptions);
  }

  cancelOrder(oOrder: Order){
    let oInsertUpdateOrderRequest = new InsertUpdateOrderRequest();
    oInsertUpdateOrderRequest.order = oOrder;
    this.apiUrl = environment.apiUrl + 'order/CancelOrder';
    return this.http.post<any>(this.apiUrl, oInsertUpdateOrderRequest);
  }

  sendOrderCreateEmail(orderId: any, userId: any){
    this.apiUrl = environment.apiUrl + 'order/SendOrderCreateEmail?orderId=' + orderId + '&userId=' + userId;
    return this.http.get<any>(this.apiUrl, httpOptions);
  }
}
