<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>{{'Welcome to our store FitTantra' | translate}}</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: +91 9836803782</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-right">
          <ul class="header-dropdown">
            <li class="compare">
              <!-- <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>{{'Compare' | translate}}</a> -->
            </li>
            <li class="mobile-wishlist">
              <!-- <a *ngIf="isLoggedIn" [routerLink]="['/pages/myorder']"><i class="fa" aria-hidden="true"></i>{{'My Order' | translate}}</a> -->
              <!-- <a *ngIf="isLoggedIn" [routerLink]="['/pages/myorder']"><i class="fa fa-heart" aria-hidden="true"></i>{{'Wishlist' | translate}}</a> -->
            </li>
            <li class="onhover-dropdown mobile-account">
              <i class="fa fa-user" aria-hidden="true"></i> {{username | translate}}
              <ul class="onhover-show-div">
                <li *ngIf="isLoggedIn  && isGuestUser">
                  <a data-lng="en" [routerLink]="['/pages/login']">
                    {{'Login' | translate}}
                  </a>
                </li>
                <li *ngIf="!isLoggedIn">
                  <a data-lng="en" [routerLink]="['/pages/login']">
                    {{'Login' | translate}}
                  </a>
                </li>
                <li *ngIf="isLoggedIn && !isGuestUser">
                  <a data-lng="es" (click)="Logout()">
                    {{'Logout' | translate}}
                  </a>
                </li>
                <li *ngIf="isLoggedIn && !isGuestUser">
                  <a data-lng="es" [routerLink]="['/pages/dashboard', 'accountInfo']">
                    {{'View Profile' | translate}}
                  </a>
                </li>
                <li *ngIf="isLoggedIn && !isGuestUser">
                  <a data-lng="es" [routerLink]="['/pages/myorder']">
                    {{'My Order' | translate}}
                  </a>
                </li>
                <li *ngIf="isLoggedIn && !isGuestUser">
                  <a data-lng="es" [routerLink]="['/pages/dashboard', 'changePassword']">
                    {{'Change Password' | translate}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
           <!-- <app-left-menu></app-left-menu>-->
            <div class="brand-logo">
              <a routerLink="/home/healthproducts" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
