import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, filter, switchMap, mergeMap } from 'rxjs/operators';
import { TokenStorageService } from '@app/shared/services/token-storage.service';
import { ProductService } from '@app/shared/api_services/product/product.service';
import { OrderService } from '@app/shared/api_services/order/order.service';
import { Order } from '@app/shared/classes/models/order';
import { UserAddress } from '@app/shared/classes/models/userAddress';
import { OrderDetails } from '@app/shared/classes/models/orderDetails';
import { Product } from '@app/shared/classes/models/product';
import { ProductVariant } from '@app/shared/classes/models/productVariant';
import { VariantImage } from '@app/shared/classes/models/variantImage';
import { OrderPaymentDetails} from '@app/shared/classes/models/orderPaymentDetails';
import { GymCouponDetails } from '@app/shared/classes/models/gymCouponDetails';

@Injectable({
    providedIn: 'root'
})

export class OrderManager{


    isLoggedIn = false;
    constructor( private oProductService: ProductService, 
        private oTokenStorageService: TokenStorageService,
        private oOrderService: OrderService){

    }

    private observableGetOrder = new BehaviorSubject(Array<Order>());
    private observableOrderPaymentDetails = new BehaviorSubject(new OrderPaymentDetails());
    private observableOrderPaymentSuccess = new BehaviorSubject(new OrderPaymentDetails());
    private observableApplyCoupon = new BehaviorSubject(new GymCouponDetails());

    saveOrder(oOrder: Order): Observable<any>{
        return this.oOrderService.saveOrder(oOrder).pipe(map((response: any) => response));
    }

    getOrder(orderId, orderDetailId): Observable<any>{

        let orders: Array<Order> = null;
        this.isLoggedIn = !!this.oTokenStorageService.getToken();
        if (this.isLoggedIn) {
            //const user = this.oTokenStorageService.getUser();
            this.oOrderService.getOrder(orderId, orderDetailId).pipe(map((response: any) => response)).subscribe((data: any) => {
                orders = new Array<Order>();
                //console.log(data)
                if(data.ResponseStatus.IsSuccess){
                    for(var i=0; i< data.OrdersList.length; i++){
                        let oOrder = new Order();
                        oOrder.orderId = data.OrdersList[i].OrderId;
                        oOrder.billingAddressId = data.OrdersList[i].BillingAddressId;
                        oOrder.createdOn = data.OrdersList[i].CreatedOn;
                        oOrder.invoiceNumber = data.OrdersList[i].InvoiceNumber;
                        oOrder.orderNumber = data.OrdersList[i].OrderNumber;
                        oOrder.orderStatus = data.OrdersList[i].OrderStatus;
                        oOrder.orderStatusId = data.OrdersList[i].OrderStatusId;
                        oOrder.shippingAddressId = data.OrdersList[i].ShippingAddressId;
                        oOrder.totalOrderValue = data.OrdersList[i].TotalOrderValue;
                        oOrder.discountAmount = data.OrdersList[i].DiscountAmount;
                        oOrder.grossOrderValue = data.OrdersList[i].GrossOrderValue;
                        oOrder.trackingId = data.OrdersList[i].TrackingId;
                        oOrder.shippingPartner = data.OrdersList[i].ShippingPartner;
                        oOrder.couponCode = data.OrdersList[i].CouponCode;
                        oOrder.userId = data.OrdersList[i].UserId;
                        oOrder.orderStatus = data.OrdersList[i].OrderStatus;
                        oOrder.orderDate = data.OrdersList[i].OrderDate;
                        oOrder.paymentID = data.OrdersList[i].PaymentID;
                        oOrder.additionalDiscountAmount = data.OrdersList[i].AdditionalDiscountAmount;
                        oOrder.additionalDiscountPercent = data.OrdersList[i].AdditionalDiscountPercent;
                        oOrder.shippingAddress = new UserAddress();
                        oOrder.billingAddress = new UserAddress();
                        //Map Shipping address
                        oOrder.shippingAddress.address = data.OrdersList[i].ShippingAddress.Address;
                        oOrder.shippingAddress.address1 = data.OrdersList[i].ShippingAddress.Address1;
                        oOrder.shippingAddress.city = data.OrdersList[i].ShippingAddress.City;
                        oOrder.shippingAddress.contactEmail = data.OrdersList[i].ShippingAddress.ContactEmail;
                        oOrder.shippingAddress.contactName = data.OrdersList[i].ShippingAddress.ContactName;
                        oOrder.shippingAddress.contactPhone = data.OrdersList[i].ShippingAddress.ContactPhone;
                        oOrder.shippingAddress.contactTypeID = data.OrdersList[i].ShippingAddress.ContactTypeID;
                        oOrder.shippingAddress.country = data.OrdersList[i].ShippingAddress.Country;
                        oOrder.shippingAddress.isActive = data.OrdersList[i].ShippingAddress.IsActive;
                        oOrder.shippingAddress.landmark = data.OrdersList[i].ShippingAddress.Landmark;
                        oOrder.shippingAddress.mobileNo = data.OrdersList[i].ShippingAddress.MobileNo;
                        oOrder.shippingAddress.state = data.OrdersList[i].ShippingAddress.State;
                        oOrder.shippingAddress.stateID = data.OrdersList[i].ShippingAddress.StateID;
                        oOrder.shippingAddress.userAddressID = data.OrdersList[i].ShippingAddress.UserAddressID;
                        oOrder.shippingAddress.userID = data.OrdersList[i].ShippingAddress.UserID;
                        oOrder.shippingAddress.zip = data.OrdersList[i].ShippingAddress.Zip;
                        //Map billing address
                        oOrder.billingAddress.address = data.OrdersList[i].BillingAddress.Address;
                        oOrder.billingAddress.address1 = data.OrdersList[i].BillingAddress.Address1;
                        oOrder.billingAddress.city = data.OrdersList[i].BillingAddress.City;
                        oOrder.billingAddress.contactEmail = data.OrdersList[i].BillingAddress.ContactEmail;
                        oOrder.billingAddress.contactName = data.OrdersList[i].BillingAddress.ContactName;
                        oOrder.billingAddress.contactPhone = data.OrdersList[i].BillingAddress.ContactPhone;
                        oOrder.billingAddress.contactTypeID = data.OrdersList[i].BillingAddress.ContactTypeID;
                        oOrder.billingAddress.country = data.OrdersList[i].BillingAddress.Country;
                        oOrder.billingAddress.isActive = data.OrdersList[i].BillingAddress.IsActive;
                        oOrder.billingAddress.landmark = data.OrdersList[i].BillingAddress.Landmark;
                        oOrder.billingAddress.mobileNo = data.OrdersList[i].BillingAddress.MobileNo;
                        oOrder.billingAddress.state = data.OrdersList[i].BillingAddress.State;
                        oOrder.billingAddress.stateID = data.OrdersList[i].BillingAddress.StateID;
                        oOrder.billingAddress.userAddressID = data.OrdersList[i].BillingAddress.UserAddressID;
                        oOrder.billingAddress.userID = data.OrdersList[i].BillingAddress.UserID;
                        oOrder.billingAddress.zip = data.OrdersList[i].BillingAddress.Zip;

                        //Map Order Details
                        oOrder.orderDetail = [];
                        for(var j =0; j< data.OrdersList[i].OrderDetail.length; j++){
                            let oOrderDetail = new OrderDetails();
                            oOrderDetail.comments =  data.OrdersList[i].OrderDetail[j].Comments;
                            oOrderDetail.createdBy = data.OrdersList[i].OrderDetail[j].CreatedBy;
                            oOrderDetail.createdOn = data.OrdersList[i].OrderDetail[j].CreatedOn;
                            oOrderDetail.discount = data.OrdersList[i].OrderDetail[j].Discount;
                            oOrderDetail.finalPrice = data.OrdersList[i].OrderDetail[j].FinalPrice;
                            oOrderDetail.quantity = data.OrdersList[i].OrderDetail[j].Quantity;
                            oOrderDetail.isGift = data.OrdersList[i].OrderDetail[j].IsGift;
                            oOrderDetail.isSpecialPackaging = data.OrdersList[i].OrderDetail[j].IsSpecialPackaging;
                            oOrderDetail.mRP = data.OrdersList[i].OrderDetail[j].MRP;
                            oOrderDetail.orderDetailsId = data.OrdersList[i].OrderDetail[j].OrderDetailsId;
                            oOrderDetail.orderId = data.OrdersList[i].OrderDetail[j].OrderId;
                            oOrderDetail.productId = data.OrdersList[i].OrderDetail[j].ProductId;
                            oOrderDetail.productOrderStatusId = data.OrdersList[i].OrderDetail[j].ProductOrderStatusId;
                            oOrderDetail.productVariantId = data.OrdersList[i].OrderDetail[j].ProductVariantId;
                            oOrderDetail.specialNoteOnPackage = data.OrdersList[i].OrderDetail[j].SpecialNoteOnPackage;
                            oOrderDetail.updatedOn = data.OrdersList[i].OrderDetail[j].UpdatedOn;
                            oOrderDetail.orderProductdetails = new Product();
                            oOrderDetail.orderProductdetails.brandId = data.OrdersList[i].OrderDetail[j].OrderProductdetails.BrandId;
                            oOrderDetail.orderProductdetails.brandName = data.OrdersList[i].OrderDetail[j].OrderProductdetails.BrandName;
                            oOrderDetail.orderProductdetails.defaultProductVariantId = data.OrdersList[i].OrderDetail[j].OrderProductdetails.DefaultProductVariantId;
                            oOrderDetail.orderProductdetails.description = data.OrdersList[i].OrderDetail[j].OrderProductdetails.Description;
                            oOrderDetail.orderProductdetails.discount = data.OrdersList[i].OrderDetail[j].OrderProductdetails.Discount;
                            oOrderDetail.orderProductdetails.finalPrice = data.OrdersList[i].OrderDetail[j].OrderProductdetails.FinalPrice;
                            oOrderDetail.orderProductdetails.inStock = data.OrdersList[i].OrderDetail[j].OrderProductdetails.InStock;
                            oOrderDetail.orderProductdetails.isActive = data.OrdersList[i].OrderDetail[j].OrderProductdetails.IsActive;
                            oOrderDetail.orderProductdetails.mRP = data.OrdersList[i].OrderDetail[j].OrderProductdetails.MRP;
                            oOrderDetail.orderProductdetails.productId = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductId;
                            oOrderDetail.orderProductdetails.productName = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductName;
                            oOrderDetail.orderProductdetails.title = data.OrdersList[i].OrderDetail[j].OrderProductdetails.Title;
                            oOrderDetail.orderProductdetails.productVariants = new Array<ProductVariant>();
                            for(var k = 0; k < data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants.length; k++){
                                let oProductVariant = new ProductVariant();
                                oProductVariant.batchNumber = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].BatchNumber;
                                oProductVariant.discount = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].Discount;
                                oProductVariant.expiryDate = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].ExpiryDate;
                                oProductVariant.finalPrice = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].FinalPrice;
                                oProductVariant.flavourID = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].FlavourID;
                                oProductVariant.flavourName = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].FlavourName;
                                oProductVariant.size = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].Size;
                                oProductVariant.imageMasterId = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].ImageMasterId;
                                oProductVariant.inStock = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].InStock;
                                oProductVariant.isActive = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].IsActive;
                                oProductVariant.mRP = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].MRP;
                                oProductVariant.unitID = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].UnitID;
                                oProductVariant.unitName = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].UnitName;
                                oProductVariant.variantDescription = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].VariantDescription;
                                oProductVariant.variantID = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].VariantID;
                                oProductVariant.variantName = data.OrdersList[i].OrderDetail[j].OrderProductdetails.ProductVariants[k].VariantName;
                                
                                oOrderDetail.orderProductdetails.productVariants.push(oProductVariant);
                            }
                            oOrderDetail.orderProductdetails.variantImages = new Array<VariantImage>();
                            for(var k = 0; k < data.OrdersList[i].OrderDetail[j].OrderProductdetails.VariantImages.length; k++){
                                let oVariantImage = new VariantImage();
                                oVariantImage.alt = data.OrdersList[i].OrderDetail[j].OrderProductdetails.VariantImages[k].Alt;
                                oVariantImage.imageMasterId = data.OrdersList[i].OrderDetail[j].OrderProductdetails.VariantImages[k].ImageMasterId;
                                oVariantImage.imageName = data.OrdersList[i].OrderDetail[j].OrderProductdetails.VariantImages[k].ImageName;
                                oVariantImage.imagePath = data.OrdersList[i].OrderDetail[j].OrderProductdetails.VariantImages[k].ImagePath;
                                oVariantImage.productId = data.OrdersList[i].OrderDetail[j].OrderProductdetails.VariantImages[k].ProductId;
                                oVariantImage.productVariantId = data.OrdersList[i].OrderDetail[j].OrderProductdetails.VariantImages[k].ProductVariantId;
                                oVariantImage.imageMasterId = data.OrdersList[i].OrderDetail[j].OrderProductdetails.VariantImages[k].Src;                                          
                                oOrderDetail.orderProductdetails.variantImages.push(oVariantImage);
                            }
                            
                            oOrder.orderDetail.push(oOrderDetail);
                        }
                        orders.push(oOrder);
                    }
                }
                
                this.observableGetOrder.next(orders);
                return this.observableGetOrder.asObservable();
            });
          }
           
        
        this.observableGetOrder.next(orders);
        return this.observableGetOrder.asObservable();
    }

    insertOrderPaymentDetails(oOrderPaymentDetails: OrderPaymentDetails): Observable<any>{

        let oOrderPaymentDetail: OrderPaymentDetails = null;
        this.isLoggedIn = !!this.oTokenStorageService.getToken();
        if (this.isLoggedIn) {
            
            this.oOrderService.insertOrderPaymentDetails(oOrderPaymentDetails).pipe(map((response: any) => response)).subscribe((data: any) => {
                oOrderPaymentDetail = new OrderPaymentDetails();
                //console.log(data)
                if(data.ResponseStatus.IsSuccess){
                    oOrderPaymentDetail.orderPaymentId = data.OrderPayment.OrderPaymentId;
                    oOrderPaymentDetail.orderId = data.OrderPayment.OrderId;
                }
                
                this.observableOrderPaymentDetails.next(oOrderPaymentDetail);
                return this.observableOrderPaymentDetails.asObservable();
            });
          }
           
        
        this.observableOrderPaymentDetails.next(oOrderPaymentDetail);
        return this.observableOrderPaymentDetails.asObservable();
    }

    getPaymentDetails(paymentId): Observable<any>{
        this.isLoggedIn = !!this.oTokenStorageService.getToken();
        let oOrderPaymentDetail: OrderPaymentDetails = null;
        if (this.isLoggedIn) {
            this.oOrderService.getPaymentDetails(paymentId).pipe(map((response: any) => response)).subscribe((data: any) => {
                //console.log(data)
                if(data.ResponseStatus.IsSuccess){
                    oOrderPaymentDetail = new OrderPaymentDetails();
                    oOrderPaymentDetail.orderId = data.OrderPayment.OrderId;
                    oOrderPaymentDetail.orderPaymentId = data.OrderPayment.OrderPaymentId;
                    oOrderPaymentDetail.pgOrderId = data.OrderPayment.PgOrderId;
                    oOrderPaymentDetail.pgPaymentId = data.OrderPayment.PgPaymentId;
                }
                
                this.observableOrderPaymentSuccess.next(oOrderPaymentDetail);
                return this.observableOrderPaymentSuccess.asObservable();
            });
          }
           
        
        this.observableOrderPaymentSuccess.next(oOrderPaymentDetail);
        return this.observableOrderPaymentSuccess.asObservable();
    }

    applyCoupon(couponCode: string): Observable<GymCouponDetails>{
        this.isLoggedIn = !!this.oTokenStorageService.getToken();
        let oGymCouponDetails: GymCouponDetails = null;
        if (this.isLoggedIn) {
            this.oOrderService.applyCoupon(couponCode).pipe(map((response: any) => response)).subscribe((data: any) => {
                //console.log(data)
                if(data.ResponseStatus.IsSuccess){
                    oGymCouponDetails = new GymCouponDetails();
                    oGymCouponDetails.couponCode = data.CouponDetails.CouponCode;
                    oGymCouponDetails.couponId = data.CouponDetails.CouponId;
                    oGymCouponDetails.discount = data.CouponDetails.Discount;
                    oGymCouponDetails.discountType = data.CouponDetails.DiscountType;
                    oGymCouponDetails.additionalDiscountPercent = data.CouponDetails.AdditionalDiscountPercent;
                }
                else{
                    oGymCouponDetails = new GymCouponDetails();
                    oGymCouponDetails.couponCode = '';
                    oGymCouponDetails.couponId = 0;
                    oGymCouponDetails.discount = '';
                    oGymCouponDetails.discountType = 0;
                    oGymCouponDetails.additionalDiscountPercent = 0;
                }
                
                this.observableApplyCoupon.next(oGymCouponDetails);
                return this.observableApplyCoupon.asObservable();
            });
          }
           
        
        this.observableApplyCoupon.next(oGymCouponDetails);
        return this.observableApplyCoupon.asObservable();
    }

    cancelOrder(oOrder: Order): Observable<any>{
        return this.oOrderService.cancelOrder(oOrder).pipe(map((response: any) => response));
    }

    sendOrderCreateEmail(orderId: any, userId: any): Observable<any>{
        return this.oOrderService.sendOrderCreateEmail(orderId, userId).pipe(map((response: any) => response));
    }
}