import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '@app/shared/classes/models/user';
import { UserAddress } from '@app/shared/classes/models/userAddress'
import { InsertUserRequest } from '@app/shared/classes/messageContracts/request/insertUserRequest'
import { InsertUserAddressRequest } from '@app/shared/classes/messageContracts/request/insertUserAddressRequest'
import { UserLoginRequest} from '@app/shared/classes/messageContracts/request/userLoginRequest'
import { UpdateUserRequest} from '@app/shared/classes/messageContracts/request/updateuserrequest'
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json'
  }),

  params: {}
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiUrl: string;

  constructor(private http: HttpClient) { }

  getUser(){
    
  }

  createAccount(oUser: User): Observable<any>{
    
    let oInsertUserRequest = new InsertUserRequest();
    oInsertUserRequest.userDetail = oUser;
    oInsertUserRequest.callerInfo = null;
    oInsertUserRequest.paging = null;
    ////console.log(oInsertUserRequest);
    this.apiUrl = environment.apiUrl + 'User/InsertUser';
    //this.apiUrl = "http://localhost:54863//api/Auth";
    //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/User/InsertUser";

    return this.http.post<InsertUserRequest>(this.apiUrl, oInsertUserRequest, httpOptions);
   // return this.http.get<any>(this.apiUrl);
  }

  login(oUser: User): Observable<any>{
    let oUserLoginRequest = new UserLoginRequest();
    oUserLoginRequest.userDetail = oUser;
    this.apiUrl = environment.apiUrl + 'user/ValidateLogin';
    //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/user/ValidateLogin";
    return this.http.post<UserLoginRequest>(this.apiUrl, oUserLoginRequest);
  }

  logout(oUser: User): Observable<any>{
    httpOptions.params = {};
    httpOptions.params = { userID: oUser.userId}
    this.apiUrl = environment.apiUrl + 'user/Logout';
    //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/user/Logout";
    return this.http.get<any>(this.apiUrl, httpOptions);
  }

  changePassword(oUser: User): Observable<any>{
    let oInsertUserRequest = new InsertUserRequest();
    oInsertUserRequest.userDetail = oUser;
    oInsertUserRequest.callerInfo = null;
    oInsertUserRequest.paging = null;
    this.apiUrl = environment.apiUrl + 'user/UserChangePassword';
    //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/user/UserChangePassword";
    return this.http.post<UserLoginRequest>(this.apiUrl, oInsertUserRequest);
  }

  saveAddress(oUserAddress: UserAddress): Observable<any>{
     let oInsertUserAddressRequest = new InsertUserAddressRequest();
     oInsertUserAddressRequest.userAddress = [];
     oInsertUserAddressRequest.userAddress[0] = oUserAddress;
    // oUserLoginRequest.userDetail = oUser;
    this.apiUrl = environment.apiUrl + 'User/InsertUserAddresss';
    //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/User/InsertUserAddresss";
    return this.http.post<InsertUserAddressRequest>(this.apiUrl, oInsertUserAddressRequest);
  }

  updateAddress(oUserAddress: UserAddress): Observable<any>{
    let oInsertUserAddressRequest = new InsertUserAddressRequest();
    oInsertUserAddressRequest.userAddress = [];
    oInsertUserAddressRequest.userAddress[0] = oUserAddress;
   // oUserLoginRequest.userDetail = oUser;
   this.apiUrl = environment.apiUrl + 'User/UpdateUserAddresss';
   //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/User/UpdateUserAddresss";
   return this.http.post<InsertUserAddressRequest>(this.apiUrl, oInsertUserAddressRequest);
 }

 deleteAddress(oUserAddress: UserAddress): Observable<any>{
  let oInsertUserAddressRequest = new InsertUserAddressRequest();
  oInsertUserAddressRequest.userAddress = [];
  oInsertUserAddressRequest.userAddress[0] = oUserAddress;
 // oUserLoginRequest.userDetail = oUser;
 this.apiUrl = environment.apiUrl + 'User/ActiveInactiveUserAddresss';
 //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/User/ActiveInactiveUserAddresss";
 return this.http.post<InsertUserAddressRequest>(this.apiUrl, oInsertUserAddressRequest);
}

  getAddress(userId: number): Observable<any>{
    this.apiUrl = environment.apiUrl + 'User/GetUserAddress?userId=' + userId;
   //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/User/GetUserAddress?userId=" + userId;
   return this.http.get<any>(this.apiUrl);
 }

 getAnonymousLogin(oUser: User){
  let oUserLoginRequest = new UserLoginRequest();
  oUserLoginRequest.userDetail = oUser;
  this.apiUrl = environment.apiUrl + 'user/GetAnonymousLogin';
    //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/user/ValidateLogin";
    return this.http.post<UserLoginRequest>(this.apiUrl, oUserLoginRequest);
 }

 updateGuestAtCheckout(oUser: User){
  let oUpdateUserRequest = new UpdateUserRequest();
  oUpdateUserRequest.userDetail = oUser;
  this.apiUrl = environment.apiUrl + 'user/UpdateGuestAtCheckout';
    //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/user/ValidateLogin";
    return this.http.post<UserLoginRequest>(this.apiUrl, oUpdateUserRequest);
 }

 validateGuestEmail(oUser: User){
  let oUpdateUserRequest = new UpdateUserRequest();
  oUpdateUserRequest.userDetail = oUser;
  this.apiUrl = environment.apiUrl + 'user/ValidateGuestEmail';
    //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/user/ValidateLogin";
    return this.http.post<UserLoginRequest>(this.apiUrl, oUpdateUserRequest);
 }

}
