import { Component, OnInit, Input } from '@angular/core';
import { MenuService} from '@app/shared/api_services/menu/menu.service'
import { map, filter, first } from 'rxjs/operators';
import{ ProductCategory } from '@app/shared/classes/models/productCategory';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True
  lstProductCategory: Array<ProductCategory> = [];

  public today: number = Date.now();

  constructor(private oMenuService: MenuService) { }

  ngOnInit(): void {
    this.getCategory();
  }

  getCategory(){
    this.oMenuService.getProductTypeCategory().pipe(map((response: any) => response)).subscribe((data: any) => {
      
      this.lstProductCategory = data.ProductCategory;
      console.log(this.lstProductCategory);
   });
  }

}
