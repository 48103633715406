<ng-template class="theme-modal" #imagepopup let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <!-- (mouseenter)="imageZoom('myImage', 'myresult');"  -->
          <img id="myImage"[src]="variantImage[imageIndex].src" class="sample-image" (click)="toggleClass()" [ngClass]="zoom == true ? 'sample-image-large' : 'sample-image' " >
         <!-- <div id="myresult" class="img-zoom-result" ></div> -->
        </div>
    </div>
</ng-template>