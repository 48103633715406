import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Product } from "@app/shared/classes/models/product";
import { ProductVariant } from "@app/shared/classes/models/productVariant";
import { ProductService } from '../../../../shared/services/product.service';
import { VariantImage } from '@app/shared/classes/models/variantImage';
import { ProductManager } from '@app/shared/manager/product/productManager';
import { ProductCartItem } from '@app/shared/classes/models/productCartItem';
import { map, filter, first } from 'rxjs/operators';
import { TokenStorageService } from '@app/shared/services/token-storage.service';
import { UserManager} from '@app/shared/manager/user/usermanager';
import { User } from '@app/shared/classes/models/user';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.scss']
})
export class QuickViewComponent implements OnInit, OnDestroy  {

  @Input() product: Product;
  @Input() currency: any;  
  @ViewChild("quickView", { static: false }) QuickView: TemplateRef<any>;

  public closeResult: string;
  public ImageSrc: string;
  public counter: number = 1;
  public modalOpen: boolean = false;
  public selectedFlavour: any;
  public uniqFlavour = [];
  public selectedSize: any;
  public selectedVariant: ProductVariant;
  public variantImages: VariantImage[] = [];
  public uniqSize = [];
  public defualtVariantImgSrc : string;
  public defualtVariantImgAlt : string;
  isLoggedIn = false;
  oUser: User = new User();

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private router: Router, private modalService: NgbModal,
    public productService: ProductService, private oProductManager: ProductManager, 
    private tokenStorageService: TokenStorageService, private oUserManager: UserManager,
    private toastrService: ToastrService) { 
      
    }

  ngOnInit(): void {
    
  }

  openModal() {
    
    
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.QuickView, { 
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Quickview' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    var defaultVariantIndex = this.product.productVariants.findIndex(x => x.isDefaultVariant == true);
    this.selectedVariant= Object.assign({}, this.product.productVariants[defaultVariantIndex]);
    this.selectedFlavour = this.selectedVariant.flavourName;
    this.Flavour(this.product.productVariants, null);
    this.Size(this.product.productVariants, this.selectedVariant.flavourName);
    
    this.setVariantImg(this.selectedVariant.variantID);
    this.isLoggedIn = !!this.tokenStorageService.getToken();
  }

  setVariantImg(variantId){

    for(var i=0 ; i < this.product.variantImages.length; i++){
      if(this.product.variantImages[i].productVariantId == variantId){
        this.defualtVariantImgSrc = this.product.variantImages[i].src;
        this.defualtVariantImgAlt = this.product.variantImages[i].alt;
        break;
      }
    }
    // this.defualtVariantImgSrc = this.product.variantImages.find(x => x.productVariantId = variantId).src;
    // this.defualtVariantImgAlt = this.product.variantImages.find(x => x.productVariantId = variantId).alt;
    // console.log(this.product.variantImages.find(x => x.productVariantId = variantId).imageMasterId)
    // console.log(this.defualtVariantImgSrc)
    // console.log(this.defualtVariantImgAlt)
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Get Product Size
  Size(variants, flavour) {
    //console.log(flavour)
    this.uniqSize = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (this.uniqSize.findIndex(x => x.size == variants[i].size) === -1 && variants[i].size && variants[i].flavourName == flavour) {
        this.uniqSize.push(variants[i])
      }
    }
    if(this.uniqSize.length > 0){
      this.selectedSize = this.uniqSize[0].size;
      
    }
    //console.log(this.uniqSize)
  }

  // Change Variants
  ChangeVariants(size, product) {
    
    this.counter = 1;
    product.productVariants.map((item) => {
      this.selectedSize = size;
      ////console.log( this.selectedSize )
      if (item.size === size && item.flavourName == this.selectedFlavour) {
      
        
        this.selectedVariant = item;
        this.setVariantImg(this.selectedVariant.variantID);
        //this.getVariantImage(this.selectedVariant.variantID);
        //this.stockFieldUpdate();
       // this.Flavour(product.productVariants, this.selectedVariant);
       // this.selectedVariant.discountedPrice = this.selectedVariant.price - ((this.selectedVariant.discount/100) * this.selectedVariant.price);
        // product.images.map((img) => {
          
        // })
      }
    })
  }

  // Increament
  increment() {
    this.counter++ ;
  }

  // Decrement
  decrement() {
    if (this.counter > 1) this.counter-- ;
  }

  // Add to cart
  async addToCart(product: Product) {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    let oProductCartItem = new ProductCartItem();
    let productCartItems = new Array<ProductCartItem>();
    productCartItems = [];
    // product.productVariants.map((item) => {
    //   if (item.variantID == this.selectedVariant.variantID) {
    //     item.isSelected = true;
    //   }
    //   else{
    //     item.isSelected = false;
    //    }
    // })
    ;
    //product.quantity = this.counter || 1;
   // const status = await this.productService.addToCart(product);
      oProductCartItem.productId = product.productId;
      oProductCartItem.productVariantId = this.selectedVariant.variantID;
      oProductCartItem.qty = this.counter;
      productCartItems.push(oProductCartItem);
      
      if(!this.isLoggedIn){
        this.oUserManager.getAnonymousLogin(this.oUser).pipe(map((response: any) => response)).subscribe((data: any) => {       
          console.log(data);
          //debugger;
          
          if(data.ResponseStatus.IsSuccess){
            this.tokenStorageService.saveToken(data.ResponseStatus.AuthToken);
            this.tokenStorageService.saveSessionId(data.ResponseStatus.SessionID);
            this.tokenStorageService.saveUser(data.UserDetail);

            this.oProductManager.addToCart(productCartItems).pipe(map((response: any) => response)).subscribe((data: any) => { 
          
              if(data.ResponseStatus.IsSuccess) {
                // this.router.navigate(['/shop/cart']);
                this.toastrService.success('Product Added to Cart Successfully')
                this.router.navigate(['/shop/cart'])
                  .then(() => {
                   window.location.reload();
                  });
              }
              if(data.ResponseStatus.IsValidation){
                debugger
                this.toastrService.info(data.ResponseStatus.lstErrorsOrValidations[0].ErrorMessage);
              }
          });
            //this.router.navigateByUrl('/home/healthproducts');
          }
          if(data.ResponseStatus.IsValidation){
            // this.showMessage = true;
            // this.message = data.ResponseStatus.lstErrorsOrValidations[0].ErrorMessage;
          }
          
        });
      }
      else{
        this.oProductManager.addToCart(productCartItems).pipe(map((response: any) => response)).subscribe((data: any) => { 
          
          if(data.ResponseStatus.IsSuccess) {
            this.toastrService.success('Product Added to Cart Successfully')
              this.router.navigate(['/shop/cart']);
            // this.router.navigate(['/shop/cart'])
            //   .then(() => {
            //    // window.location.reload();
            //   });
          }
          if(data.ResponseStatus.IsValidation){
            debugger
            this.toastrService.info(data.ResponseStatus.lstErrorsOrValidations[0].ErrorMessage);
          }
      });
      }       
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

  ChangeFirstVariants(flavour, product) {
    
    this.counter = 1;
    //this.Size(product.productVariants, flavour);
    product.productVariants.map((item) => {
      this.selectedFlavour = flavour;
      
      if (item.flavourName === flavour && item.size == this.selectedSize) {

        
        this.selectedVariant = item;
        this.setVariantImg(this.selectedVariant.variantID);
       // this.getVariantImage(this.selectedVariant.variantID);
        //this.stockFieldUpdate();
       // this.selectedVariant.finalPrice = this.selectedVariant.price - ((this.selectedVariant.discount/100) * this.selectedVariant.price);
        product.images.map((img) => {
          
        })
      }

    })
  }

  getVariantImage(variantID){
    this.variantImages = [];
    this.variantImages = this.product.variantImages.filter(x => x.productVariantId == variantID);
    //console.log(variantID);
    //console.log(this.variantImages);
  }

   // Get Product Flavour
   Flavour(variants, selectedVariant) {
    this.uniqFlavour = []
    
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if(selectedVariant != null){
        if (this.uniqFlavour.indexOf(variants[i].flavourName) === -1 && variants[i].flavourName && variants[i].size == selectedVariant.size) {
          this.uniqFlavour.push(variants[i].flavourName)
        }
      }
      else{
        if (this.uniqFlavour.indexOf(variants[i].flavourName) === -1 && variants[i].flavourName) {
          this.uniqFlavour.push(variants[i].flavourName)
        }
      }
      
    }
    //console.log(this.uniqFlavour);
  }

}
