import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { map, filter, first } from 'rxjs/operators';
import { User} from '@app/shared/classes/models/user';
import { UserManager } from '@app/shared/manager/user/usermanager'
import { TokenStorageService } from '@app/shared/services/token-storage.service';

@Component({
  selector: 'app-guestmodal',
  templateUrl: './guestmodal.component.html',
  styleUrls: ['./guestmodal.component.scss']
})
export class GuestmodalComponent implements OnInit, OnDestroy {

  @ViewChild("guestmodal", { static: false }) GuestModal: TemplateRef<any>;

  public modalOpen: boolean = false;
  public closeResult: string;
  public isLoginAsGuest: boolean = false;
  public oUser: User;
  public showMessage: boolean = false;
  public message: string = '';

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal,
  private oUserManager: UserManager, private tokenStorage: TokenStorageService, private router: Router) { }

  ngOnInit(): void {
    this.oUser = new User();
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

  login(){
    if(this.isValidate()){
      let guestUser = this.tokenStorage.getUser();
      this.oUser.guestID = guestUser.GuestID;
      this.oUserManager.login(this.oUser).pipe(map((response: any) => response)).subscribe((data: any) => { 
        //debugger
        console.log(data);
        if(data.ResponseStatus.IsSuccess){
          this.tokenStorage.saveToken(data.ResponseStatus.AuthToken);
          this.tokenStorage.saveSessionId(data.ResponseStatus.SessionID);
          this.tokenStorage.saveUser(data.UserDetail);
         // this.router.navigateByUrl('/shop/checkout');
          this.router.navigate(['/shop/checkout'])
                  .then(() => {
                   window.location.reload();
                  });
        }
        if(data.ResponseStatus.IsValidation){
          this.showMessage = true;
          this.message = data.ResponseStatus.lstErrorsOrValidations[0].ErrorMessage;
        }
        
      });
    }
    else{
      this.showMessage = true;
      this.message = 'Please enter all fields properly';
    }
  }

  isValidate(): boolean{
    let isValid = true;
    
    if(this.oUser.userEmail == ""){
      isValid = false;
    }
    
    if(this.oUser.userPswd == ""){
      isValid = false;
    }
    

    return isValid;
  }

  isValidateAsGuest(str): boolean{
    let isValid = true;
    var mobileNoRegex = /^[0]?[789]\d{9}$/;

    if(this.oUser.userFirstName == ""){
      isValid = false;
    }

    if(this.oUser.userLastName == ""){
      isValid = false;
    }
    
    if(this.oUser.userEmail == ""){
      isValid = false;
    }
    else{
      if(!this.isValidEmailAddress(str)){
        isValid = false;
      }
    }
    if(this.oUser.userMobileNumber == ""){
      isValid = false;
    }
    else{
      if(!mobileNoRegex.test(this.oUser.userMobileNumber)){
        isValid = false;
      }
    }
     

    return isValid;
  }

  isValidEmailAddress(str) {
    var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!filter.test(str)) {                     
            return false;
        }
        else{
          return true;
          
        }       
  }

  loginAsGuest(){
    let guestUser = this.tokenStorage.getUser();
    this.oUser.guestID = guestUser.GuestID;
    this.oUser.userId = guestUser.UserID;
    //debugger

    if(this.isValidateAsGuest(this.oUser.userEmail)){
      this.oUserManager.validateGuestEmail(this.oUser).pipe(map((response: any) => response)).subscribe((data: any) => { 
        //debugger
        console.log(data);
        if(data.ResponseStatus.IsSuccess){
  
          this.oUserManager.updateGuestAtCheckout(this.oUser).pipe(map((response: any) => response)).subscribe((data: any) => { 
            //debugger
            console.log(data);
            if(data.ResponseStatus.IsSuccess){
              this.tokenStorage.saveUser(data.UserDetail);
             // this.router.navigateByUrl('/shop/checkout');
              this.router.navigate(['/shop/checkout'])
                      .then(() => {
                       window.location.reload();
                      });
            }
            if(data.ResponseStatus.IsValidation){
              // this.showMessage = true;
              // this.message = data.ResponseStatus.lstErrorsOrValidations[0].ErrorMessage;
            }
            
          });
        }
        if(data.ResponseStatus.IsValidation){
          this.showMessage = true;
          this.message = data.ResponseStatus.lstErrorsOrValidations[0].ErrorMessage;
        }
        
      });
    }

    else{
      this.showMessage = true;
      this.message = 'Please enter all fields properly';
    }

   

    
  }

  resetModelValue(){
    this.oUser = new User();
    this.showMessage = false;
    this.message = '';
  }
  openModal() {
    
    
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.GuestModal, { 
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Quickview' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  changeTab(){
    if(!this.isLoginAsGuest){
      this.isLoginAsGuest = true;
      this.showMessage = false;
      this.oUser = new User();
    }
    else{
      this.isLoginAsGuest = false;
      this.showMessage = false;
      this.oUser = new User();
    }
  }

}
