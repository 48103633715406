import { ProductCategory } from '@app/shared/classes/models/productCategory';
import { ProductType } from '@app/shared/classes/models/productType';
import { ProductFeature } from '@app/shared/classes/models/productFeature';
import { ProductVariant } from '@app/shared/classes/models/productVariant';
import { VariantImage } from '@app/shared/classes/models/variantImage';

export class Product{
    productId: number;
    productName: string;
    description: string;
    title: string;
    brandId: number;
    brandName: string;
    defaultProductVariantId: number;
    mRP: number;
    discount: number;
    finalPrice: number;
    inStock: number;
    productCategorys: Array<ProductCategory>;
    productTypeList: Array<ProductType>;
    productFeatures: Array<ProductFeature>;
    isActive: boolean;
    productVariants: Array<ProductVariant>;
    variantImages: Array<VariantImage>;
}