import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { map, filter, first } from 'rxjs/operators';
import { User} from '@app/shared/classes/models/user';
import { Order} from '@app/shared/classes/models/order';
import { OrderManager } from '@app/shared/manager/order/ordermanager'
import { TokenStorageService } from '@app/shared/services/token-storage.service';


@Component({
  selector: 'app-order-cancel-confirm',
  templateUrl: './order-cancel-confirm.component.html',
  styleUrls: ['./order-cancel-confirm.component.scss']
})
export class OrderCancelConfirmComponent implements OnInit, OnDestroy {

  @ViewChild("orderCancelConfirmModal", { static: false }) OrderCancelConfirmModal: TemplateRef<any>;
  @Input() orderId: any;


  public modalOpen: boolean = false;
  public closeResult: string;
  public isLoginAsGuest: boolean = false;
  public oUser: User;
  public showMessage: boolean = false;
  public message: string = '';
  userId: string;
  isLoggedIn = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal,
  private oOrderManager: OrderManager, private tokenStorage: TokenStorageService, private router: Router) { }

  ngOnInit(): void {

    this.isLoggedIn = !!this.tokenStorage.getToken();
    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.userId = user.UserID;
    }

  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

  openModal() {
    
    
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.OrderCancelConfirmModal, { 
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Quickview' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  cancelOrder(){
    debugger;
    let order = new Order();
    order.orderId = this.orderId;
    order.userId = parseInt(this.userId);
    this.oOrderManager.cancelOrder(order).pipe(map((response: any) => response)).subscribe((data: any) => { 
      //console.log(data)
      //debugger
      if(data.ResponseStatus.IsSuccess) {
        window.location.reload();
      }
    });
  }

  no(){
    debugger
    this.modalOpen = false;
  }

}
