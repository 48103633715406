import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {User} from '@app/shared/classes/models/user';
import { ProductService } from '@app/shared/api_services/product/product.service';
import { map, filter, switchMap, mergeMap } from 'rxjs/operators';
import { Product } from '@app/shared/classes/models/product';
import { ProductFlavour } from '@app/shared/classes/models/productFlavour';
import { ProductSize } from '@app/shared/classes/models/productSize';
import { ProductWrapper } from '@app/shared/classes/models/productWrapper';
import { ProductCategory } from '@app/shared/classes/models/productCategory';
import { ProductCart } from '@app/shared/classes/models/productCart';
import { ProductCartItem } from '@app/shared/classes/models/productCartItem';
import { ProductType } from '@app/shared/classes/models/productType';
import { ProductVariant } from '@app/shared/classes/models/productVariant';
import { VariantImage } from '@app/shared/classes/models/variantImage';
import { TokenStorageService } from '@app/shared/services/token-storage.service';
import { UpdateCartResponse } from '@app/shared/classes/messageContracts/response/updateCartResponse';


@Injectable({
    providedIn: 'root'
})

export class ProductManager{
    isLoggedIn = false;

    constructor( private oProductService: ProductService, private oTokenStorageService: TokenStorageService){

    }

    private observableProductWrapper = new BehaviorSubject(new ProductWrapper());
    private observableUpdateCartResponse = new BehaviorSubject(new UpdateCartResponse());

    getProductList(productCategoryId, productTypeId, isTopCategory, onFlashSale, searchText): Observable<any>{
        let oProductWrapper: ProductWrapper = null;
        this.oProductService.GetProductList(productCategoryId, productTypeId, isTopCategory, onFlashSale, searchText).pipe(map((response: any) => response)).subscribe((data: any) => {
            ;
            let oProductWrapper: ProductWrapper = new ProductWrapper();
            if (data.ResponseStatus.IsSuccess == true) {
                
                if(data.ProductFlavours.length > 0 ){
                    for(var i=0; i< data.ProductFlavours.length; i++){
                        let oProductFlavour = new ProductFlavour();
                        oProductFlavour.flavourName = data.ProductFlavours[i].FlavourName;
                        oProductFlavour.productVariantFlavourId = data.ProductFlavours[i].ProductVariantFlavourId;
                        oProductWrapper.productFlavours.push(oProductFlavour);
                    }
                    
                }
                if(data.ProductSizes.length > 0 ){
                    for(var i=0; i< data.ProductSizes.length; i++){
                        let oProductSize = new ProductSize();
                        oProductSize.unitName = data.ProductSizes[i].UnitName;
                        oProductSize.unitId = data.ProductSizes[i].UnitId;
                        oProductSize.size = data.ProductSizes[i].Size;
                        oProductWrapper.productSizes.push(oProductSize);
                    }
                }

                if(data.Products.length > 0 ){
                    for(var i=0; i< data.Products.length; i++){
                        let oProduct = new Product();
                        oProduct.brandId = data.Products[i].BrandId;
                        oProduct.brandName = data.Products[i].BrandName;
                        oProduct.description = data.Products[i].Description;
                        oProduct.discount = data.Products[i].Discount;
                        oProduct.finalPrice = data.Products[i].FinalPrice;
                        oProduct.inStock = data.Products[i].InStock;
                        oProduct.mRP = data.Products[i].MRP;
                        oProduct.productId = data.Products[i].ProductId;
                        oProduct.productName = data.Products[i].ProductName;
                        oProduct.title = data.Products[i].Title;
                        oProduct.productCategorys = [];
                        oProduct.productTypeList = [];
                        oProduct.productVariants = [];
                        oProduct.variantImages = [];

                        for(var j = 0; j < data.Products[i].ProductCategorys.length; j++){
                            let oProductCategory = new ProductCategory();
                            oProductCategory.createdBy = data.Products[i].ProductCategorys[j].CreatedBy;
                            oProductCategory.createdOn = data.Products[i].ProductCategorys[j].CreatedOn;
                            oProductCategory.isActive = data.Products[i].ProductCategorys[j].IsActive;
                            oProductCategory.productCategoryDesc = data.Products[i].ProductCategorys[j].ProductCategoryDesc;
                            oProductCategory.productCategoryId = data.Products[i].ProductCategorys[j].ProductCategoryId;
                            oProductCategory.updatedBy = data.Products[i].ProductCategorys[j].UpdatedBy;
                            oProductCategory.updatedOn = data.Products[i].ProductCategorys[j].UpdatedOn;
                            oProduct.productCategorys.push(oProductCategory);
                        }

                        for(var j = 0; j < data.Products[i].ProductTypeList.length; j++){
                            let oProductType = new ProductType();
                            oProductType.createdBy = data.Products[i].ProductTypeList[j].CreatedBy;
                            oProductType.createdOn = data.Products[i].ProductTypeList[j].CreatedOn;
                            oProductType.isActive = data.Products[i].ProductTypeList[j].IsActive;
                            oProductType.productTypeDesc = data.Products[i].ProductTypeList[j].ProductTypeDesc;
                            oProductType.productTypeId = data.Products[i].ProductTypeList[j].ProductTypeId;
                            oProductType.updatedBy = data.Products[i].ProductTypeList[j].UpdatedBy;
                            oProductType.updatedOn = data.Products[i].ProductTypeList[j].UpdatedOn;
                            oProduct.productTypeList.push(oProductType);
                        }

                        for(var j = 0; j < data.Products[i].ProductVariants.length; j++){
                            let oProductVariant = new ProductVariant();
                            oProductVariant.batchNumber = data.Products[i].ProductVariants[j].BatchNumber;
                            oProductVariant.discount = data.Products[i].ProductVariants[j].Discount;
                            oProductVariant.isActive = data.Products[i].ProductVariants[j].IsActive;
                            oProductVariant.expiryDate = data.Products[i].ProductVariants[j].ExpiryDate;
                            oProductVariant.finalPrice = data.Products[i].ProductVariants[j].FinalPrice;
                            oProductVariant.flavourID = data.Products[i].ProductVariants[j].FlavourID;
                            oProductVariant.flavourName = data.Products[i].ProductVariants[j].FlavourName;
                            oProductVariant.imageMasterId = data.Products[i].ProductVariants[j].ImageMasterId;
                            oProductVariant.inStock = data.Products[i].ProductVariants[j].InStock;
                            oProductVariant.isDefaultVariant = data.Products[i].ProductVariants[j].IsDefaultVariant;
                            oProductVariant.mRP = data.Products[i].ProductVariants[j].MRP;
                            oProductVariant.productVariantStockId = data.Products[i].ProductVariants[j].ProductVariantStockId;
                            oProductVariant.size = data.Products[i].ProductVariants[j].Size;
                            oProductVariant.unitID = data.Products[i].ProductVariants[j].UnitID;
                            oProductVariant.unitName = data.Products[i].ProductVariants[j].UnitName;
                            oProductVariant.variantCode = data.Products[i].ProductVariants[j].VariantCode;
                            oProductVariant.variantDescription = data.Products[i].ProductVariants[j].VariantDescription;
                            oProductVariant.variantID = data.Products[i].ProductVariants[j].VariantID;
                            oProductVariant.variantName = data.Products[i].ProductVariants[j].VariantName;
                            oProductVariant.variantStocks = data.Products[i].ProductVariants[j].VariantStocks;
                            oProduct.productVariants.push(oProductVariant);
                        }

                        for(var j = 0; j < data.Products[i].VariantImages.length; j++){
                            let oVariantImage = new VariantImage();
                            oVariantImage.alt = data.Products[i].VariantImages[j].Alt;
                            oVariantImage.imageMasterId = data.Products[i].VariantImages[j].ImageMasterId;
                            oVariantImage.imageName = data.Products[i].VariantImages[j].ImageName;
                            oVariantImage.imagePath = data.Products[i].VariantImages[j].ImagePath;
                            oVariantImage.productId = data.Products[i].VariantImages[j].ProductId;
                            oVariantImage.productVariantId = data.Products[i].VariantImages[j].ProductVariantId;
                            oVariantImage.productVariants = data.Products[i].VariantImages[j].ProductVariants;
                            oVariantImage.src = data.Products[i].VariantImages[j].Src;
                            oProduct.variantImages.push(oVariantImage);
                        }
                        oProductWrapper.products.push(oProduct);
                    }
                }
              

                this.observableProductWrapper.next(oProductWrapper);
                return this.observableProductWrapper.asObservable();
            }
        });

        this.observableProductWrapper.next(oProductWrapper);
        return this.observableProductWrapper.asObservable();
    }

    getProductById(id: number): Observable<any>{
        
        let oProductWrapper: ProductWrapper = null;
        this.oProductService.getProductById(id).pipe(map((response: any) => response)).subscribe((data: any) => {
            ;
            let oProductWrapper: ProductWrapper = new ProductWrapper();
            if (data.ResponseStatus.IsSuccess == true) {
                
                if(data.Products.length > 0 ){
                    for(var i=0; i< data.Products.length; i++){
                        let oProduct = new Product();
                        oProduct.brandId = data.Products[i].BrandId;
                        oProduct.brandName = data.Products[i].BrandName;
                        oProduct.description = data.Products[i].Description;
                        oProduct.discount = data.Products[i].Discount;
                        oProduct.finalPrice = data.Products[i].FinalPrice;
                        oProduct.inStock = data.Products[i].InStock;
                        oProduct.mRP = data.Products[i].MRP;
                        oProduct.productId = data.Products[i].ProductId;
                        oProduct.productName = data.Products[i].ProductName;
                        oProduct.title = data.Products[i].Title;
                        oProduct.productCategorys = [];
                        oProduct.productTypeList = [];
                        oProduct.productVariants = [];
                        oProduct.variantImages = [];

                        for(var j = 0; j < data.Products[i].ProductCategorys.length; j++){
                            let oProductCategory = new ProductCategory();
                            oProductCategory.createdBy = data.Products[i].ProductCategorys[j].CreatedBy;
                            oProductCategory.createdOn = data.Products[i].ProductCategorys[j].CreatedOn;
                            oProductCategory.isActive = data.Products[i].ProductCategorys[j].IsActive;
                            oProductCategory.productCategoryDesc = data.Products[i].ProductCategorys[j].ProductCategoryDesc;
                            oProductCategory.productCategoryId = data.Products[i].ProductCategorys[j].ProductCategoryId;
                            oProductCategory.updatedBy = data.Products[i].ProductCategorys[j].UpdatedBy;
                            oProductCategory.updatedOn = data.Products[i].ProductCategorys[j].UpdatedOn;
                            oProduct.productCategorys.push(oProductCategory);
                        }

                        for(var j = 0; j < data.Products[i].ProductTypeList.length; j++){
                            let oProductType = new ProductType();
                            oProductType.createdBy = data.Products[i].ProductTypeList[j].CreatedBy;
                            oProductType.createdOn = data.Products[i].ProductTypeList[j].CreatedOn;
                            oProductType.isActive = data.Products[i].ProductTypeList[j].IsActive;
                            oProductType.productTypeDesc = data.Products[i].ProductTypeList[j].ProductTypeDesc;
                            oProductType.productTypeId = data.Products[i].ProductTypeList[j].ProductTypeId;
                            oProductType.updatedBy = data.Products[i].ProductTypeList[j].UpdatedBy;
                            oProductType.updatedOn = data.Products[i].ProductTypeList[j].UpdatedOn;
                            oProduct.productTypeList.push(oProductType);
                        }

                        for(var j = 0; j < data.Products[i].ProductVariants.length; j++){
                            let oProductVariant = new ProductVariant();
                            oProductVariant.batchNumber = data.Products[i].ProductVariants[j].BatchNumber;
                            oProductVariant.discount = data.Products[i].ProductVariants[j].Discount;
                            oProductVariant.isActive = data.Products[i].ProductVariants[j].IsActive;
                            oProductVariant.expiryDate = data.Products[i].ProductVariants[j].ExpiryDate;
                            oProductVariant.finalPrice = data.Products[i].ProductVariants[j].FinalPrice;
                            oProductVariant.flavourID = data.Products[i].ProductVariants[j].FlavourID;
                            oProductVariant.flavourName = data.Products[i].ProductVariants[j].FlavourName;
                            oProductVariant.imageMasterId = data.Products[i].ProductVariants[j].ImageMasterId;
                            oProductVariant.inStock = data.Products[i].ProductVariants[j].InStock;
                            oProductVariant.isDefaultVariant = data.Products[i].ProductVariants[j].IsDefaultVariant;
                            oProductVariant.mRP = data.Products[i].ProductVariants[j].MRP;
                            oProductVariant.productVariantStockId = data.Products[i].ProductVariants[j].ProductVariantStockId;
                            oProductVariant.size = data.Products[i].ProductVariants[j].Size;
                            oProductVariant.unitID = data.Products[i].ProductVariants[j].UnitID;
                            oProductVariant.unitName = data.Products[i].ProductVariants[j].UnitName;
                            oProductVariant.variantCode = data.Products[i].ProductVariants[j].VariantCode;
                            oProductVariant.variantDescription = data.Products[i].ProductVariants[j].VariantDescription;
                            oProductVariant.variantID = data.Products[i].ProductVariants[j].VariantID;
                            oProductVariant.variantName = data.Products[i].ProductVariants[j].VariantName;
                            oProductVariant.variantStocks = data.Products[i].ProductVariants[j].VariantStocks;
                            oProduct.productVariants.push(oProductVariant);
                        }

                        for(var j = 0; j < data.Products[i].VariantImages.length; j++){
                            let oVariantImage = new VariantImage();
                            oVariantImage.alt = data.Products[i].VariantImages[j].Alt;
                            oVariantImage.imageMasterId = data.Products[i].VariantImages[j].ImageMasterId;
                            oVariantImage.imageName = data.Products[i].VariantImages[j].ImageName;
                            oVariantImage.imagePath = data.Products[i].VariantImages[j].ImagePath;
                            oVariantImage.productId = data.Products[i].VariantImages[j].ProductId;
                            oVariantImage.productVariantId = data.Products[i].VariantImages[j].ProductVariantId;
                            oVariantImage.productVariants = data.Products[i].VariantImages[j].ProductVariants;
                            oVariantImage.src = data.Products[i].VariantImages[j].Src;
                            oProduct.variantImages.push(oVariantImage);
                        }
                        oProductWrapper.products.push(oProduct);
                    }
                }
              
              //  oProductWrapper.products = oProductWrapper.products.filter(x => x.productId == id);
                this.observableProductWrapper.next(oProductWrapper);
                return this.observableProductWrapper.asObservable();
            }
        });

        this.observableProductWrapper.next(oProductWrapper);
        return this.observableProductWrapper.asObservable();
    }

    getCart(): Observable<UpdateCartResponse>{
        
        let oUpdateCartResponse: UpdateCartResponse = null;
        this.oProductService.getCart().pipe(map((response: any) => response)).subscribe((data: any) => {
            console.log(data);
            if(data.ResponseStatus.IsSuccess){
                let oUpdateCartResponse = new UpdateCartResponse();
                oUpdateCartResponse.cart = new ProductCart();
                oUpdateCartResponse.cart.productCartItems = new Array<ProductCartItem>();
                oUpdateCartResponse.cart.products = new Array<Product>();
                oUpdateCartResponse.cart.productCartItems = []; 
                oUpdateCartResponse.cart.products = []; 
                if(data.Cart.ProductCartItems != null){
                    for(var i=0; i< data.Cart.ProductCartItems.length; i++){
                        let oProductCartItem = new ProductCartItem();
                        oProductCartItem.productId = data.Cart.ProductCartItems[i].ProductId;
                        oProductCartItem.productVariantId = data.Cart.ProductCartItems[i].ProductVariantId;
                        oProductCartItem.qty = data.Cart.ProductCartItems[i].Qty;
                        oUpdateCartResponse.cart.productCartItems.push(oProductCartItem);
                    }
                }
                if(data.Cart.Products != null){
                    for(var i=0; i< data.Cart.Products.length; i++){
                        let oProduct = new Product();
                        oProduct.brandId = data.Cart.Products[i].BrandId;
                        oProduct.brandName = data.Cart.Products[i].BrandName;
                        oProduct.defaultProductVariantId = data.Cart.Products[i].DefaultProductVariantId;
                        oProduct.description = data.Cart.Products[i].Description;
                        oProduct.discount = data.Cart.Products[i].Discount;
                        oProduct.finalPrice = data.Cart.Products[i].FinalPrice;
                        oProduct.inStock = data.Cart.Products[i].InStock;
                        oProduct.mRP = data.Cart.Products[i].MRP;
                        oProduct.productId = data.Cart.Products[i].ProductId;
                        oProduct.productName = data.Cart.Products[i].ProductName;
                        oProduct.title = data.Cart.Products[i].Title;
                        oProduct.productVariants = [];
                        oProduct.variantImages = [];
    
                        for(var j=0; j<data.Cart.Products[i].ProductVariants.length; j++){
                            let oProductVariant = new ProductVariant();
                            oProductVariant.batchNumber = data.Cart.Products[i].ProductVariants[j].BatchNumber;
                            oProductVariant.discount = data.Cart.Products[i].ProductVariants[j].Discount;
                            oProductVariant.expiryDate = data.Cart.Products[i].ProductVariants[j].ExpiryDate;
                            oProductVariant.finalPrice = data.Cart.Products[i].ProductVariants[j].FinalPrice;
                            oProductVariant.flavourID = data.Cart.Products[i].ProductVariants[j].FlavourID;
                            oProductVariant.flavourName = data.Cart.Products[i].ProductVariants[j].FlavourName;
                            oProductVariant.imageMasterId = data.Cart.Products[i].ProductVariants[j].ImageMasterId;
                            oProductVariant.inStock = data.Cart.Products[i].ProductVariants[j].InStock;
                            oProductVariant.isDefaultVariant = data.Cart.Products[i].ProductVariants[j].IsDefaultVariant;
                            oProductVariant.mRP = data.Cart.Products[i].ProductVariants[j].MRP;
                            oProductVariant.mfgDate = data.Cart.Products[i].ProductVariants[j].MfgDate;
                            oProductVariant.productVariantStockId = data.Cart.Products[i].ProductVariants[j].ProductVariantStockId;
                            oProductVariant.size = data.Cart.Products[i].ProductVariants[j].Size;
                            oProductVariant.unitID = data.Cart.Products[i].ProductVariants[j].UnitID;
                            oProductVariant.unitName = data.Cart.Products[i].ProductVariants[j].UnitName;
                            oProductVariant.variantCode = data.Cart.Products[i].ProductVariants[j].VariantCode;
                            oProductVariant.variantDescription = data.Cart.Products[i].ProductVariants[j].VariantDescription;
                            oProductVariant.variantID = data.Cart.Products[i].ProductVariants[j].VariantID;
                            oProductVariant.variantName = data.Cart.Products[i].ProductVariants[j].VariantName;
                            oProduct.productVariants.push(oProductVariant)
                        }
    
                        for(var j=0; j<data.Cart.Products[i].VariantImages.length; j++){
                            let oVariantImage = new VariantImage();
                            oVariantImage.alt = data.Cart.Products[i].VariantImages[j].Alt;
                            oVariantImage.imageMasterId = data.Cart.Products[i].VariantImages[j].ImageMasterId;
                            oVariantImage.imageName = data.Cart.Products[i].VariantImages[j].ImageName;
                            oVariantImage.imagePath = data.Cart.Products[i].VariantImages[j].ImagePath;
                            oVariantImage.productId = data.Cart.Products[i].VariantImages[j].ProductId;
                            oVariantImage.productVariantId = data.Cart.Products[i].VariantImages[j].ProductVariantId;
                            oVariantImage.src = data.Cart.Products[i].VariantImages[j].Src;
                            oProduct.variantImages.push(oVariantImage)
                        }
    
                        oUpdateCartResponse.cart.products.push(oProduct);
                    }
                }
                
                
            this.observableUpdateCartResponse.next(oUpdateCartResponse);
            return this.observableUpdateCartResponse.asObservable();

            }
            
        });

        this.observableUpdateCartResponse.next(oUpdateCartResponse);
        return this.observableUpdateCartResponse.asObservable();
    }


    addToCart(productCartItems: Array<ProductCartItem>): Observable<any>{
        this.isLoggedIn = !!this.oTokenStorageService.getToken();
        let oProductCart = new ProductCart();
        if (this.isLoggedIn) {
            const user = this.oTokenStorageService.getUser();
            oProductCart.userId = user.UserID;
            oProductCart.authToken = this.oTokenStorageService.getToken();
            oProductCart.userSessionId = this.oTokenStorageService.getSessionId();
            oProductCart.productCartItems = productCartItems;
          }
          return this.oProductService.addToCart(oProductCart).pipe(map((response: any) => response));

    }

    deleteCart(productCartItems: Array<ProductCartItem>): Observable<any>{
        this.isLoggedIn = !!this.oTokenStorageService.getToken();
        let oProductCart = new ProductCart();
        if (this.isLoggedIn) {
            const user = this.oTokenStorageService.getUser();
            oProductCart.userId = user.UserID;
            oProductCart.authToken = this.oTokenStorageService.getToken();
            oProductCart.userSessionId = this.oTokenStorageService.getSessionId();
            oProductCart.productCartItems = productCartItems;
          }
          return this.oProductService.deleteCart(oProductCart).pipe(map((response: any) => response));

    }

}