import { OrderProducts } from '@app/shared/classes/models/orderProducts';
import { Product } from '@app/shared/classes/models/product';

export class OrderDetails{
    orderDetailsId: number;
    orderId: number;
    productId: number;
    productVariantId: number;
    quantity: number;
    mRP: number;
    discount: number;
    finalPrice: number;
    productOrderStatusId: number;
    isGift: boolean;
    isSpecialPackaging: boolean;
    specialNoteOnPackage: string;
    comments: string;
    orderProductdetails: Product;
    createdBy: string;
    createdOn: string;
    updatedBy: string;
    updatedOn: string;
}