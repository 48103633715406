export class OrderPaymentDetails{
    orderPaymentId: number;
    orderId: number;
    pgOrderId: string;
    pgPaymentId: string;
    pgSignature: string;
    pgTimeStamp: number;
    paymentType: string;
    paymentRefId: string;
    amount: number;
    bankName: string;
    comments: string;
    isSuccess: boolean;
    errorCode: string;
    errorDescription: string;
    errorSource: string;
    errorStep: string;
    errorReason: string;
    userId: number;
}