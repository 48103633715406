import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Variants } from "../../../classes/product";
import { Product} from '@app/shared/classes/models/product';
import { User} from '@app/shared/classes/models/user';
import { ProductVariant } from '@app/shared/classes/models/productVariant';
import { ProductCartItem } from '@app/shared/classes/models/productCartItem';
import { ProductService } from "../../../services/product.service";
import { ProductManager} from '@app/shared/manager/product/productManager';
import { map, filter, first } from 'rxjs/operators';
import { MessageService } from '@app/shared/services/message.service';
import { TokenStorageService } from '@app/shared/services/token-storage.service';
import { UserManager} from '@app/shared/manager/user/usermanager';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  defaltVariant: ProductVariant;
  isLoggedIn = false;
  oUser: User = new User();
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string

  constructor(private productService: ProductService, private oProductManager: ProductManager,
    private oMessageService: MessageService, private tokenStorageService: TokenStorageService, 
    private oUserManager: UserManager, private toastrService: ToastrService) { }

  ngOnInit(): void {
    console.log(this.QuickView);
    //this.product = new Product();
    console.log(this.product);
    
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    //this.defaltVariant= Object.assign({}, this.product.productVariants[0]);
    //this.product.mRP

  }

  getImgIndex(){
    var variantId = this.product.productVariants.find(x => x.isDefaultVariant == true).variantID;
    var imgIndex = this.product.variantImages.findIndex(x => x.productVariantId == variantId);
   
    return imgIndex;
  }

  // Get Product Color
  Color(variants) {
    // const uniqColor = [];
    // for (let i = 0; i < Object.keys(variants).length; i++) {
    //   if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
    //     uniqColor.push(variants[i].color)
    //   }
    // }
    // return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
 
    // product.variants.map((item) => {
    //   if (item.color === color) {
    //     ////console.log(item)
        
    //     //this.defaltVariant.mRP = item.price;
    //     product.images.map((img) => {
    //       if (img.image_id === item.image_id) {
    //         this.ImageSrc = img.src;
    //       }
    //     })
    //   }
    // })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    //this.ImageSrc = src;
  }

  addToCart(product: Product) {
    debugger
    let oProductCartItem = new ProductCartItem();
    let productCartItems = new Array<ProductCartItem>();
    productCartItems = [];
    
    
    //product.quantity = this.counter || 1;
   // const status = await this.productService.addToCart(product);
      oProductCartItem.productId = product.productId;
      oProductCartItem.productVariantId = product.productVariants.find( x=> x.isDefaultVariant == true).variantID;
      oProductCartItem.qty = 1;
      productCartItems.push(oProductCartItem);
      debugger
      this.isLoggedIn = !!this.tokenStorageService.getToken();
      if(product.inStock > 0){
        if(!this.isLoggedIn){
          this.oUserManager.getAnonymousLogin(this.oUser).pipe(map((response: any) => response)).subscribe((data: any) => {       
            console.log(data);
            //debugger;
            
            if(data.ResponseStatus.IsSuccess){
              this.tokenStorageService.saveToken(data.ResponseStatus.AuthToken);
              this.tokenStorageService.saveSessionId(data.ResponseStatus.SessionID);
              this.tokenStorageService.saveUser(data.UserDetail);
  
              this.oProductManager.addToCart(productCartItems).pipe(map((response: any) => response)).subscribe((data: any) => { 
            
                if(data.ResponseStatus.IsSuccess) {
                  // this.router.navigate(['/shop/cart']);
                  // this.router.navigate(['/shop/cart'])
                  //   .then(() => {
                  //    window.location.reload();
                  //   });
                  this.getCart();
                }
                if(data.ResponseStatus.IsValidation){
                  debugger
                  this.toastrService.info(data.ResponseStatus.lstErrorsOrValidations[0].ErrorMessage);
                }
            });
              //this.router.navigateByUrl('/home/healthproducts');
            }
            if(data.ResponseStatus.IsValidation){
              // this.showMessage = true;
              // this.message = data.ResponseStatus.lstErrorsOrValidations[0].ErrorMessage;
              
            }
            
          });
        }
        else{
          this.oProductManager.addToCart(productCartItems).pipe(map((response: any) => response)).subscribe((data: any) => { 
            debugger
            if(data.ResponseStatus.IsSuccess) {
              // this.router.navigate(['/shop/cart']);
              // this.router.navigate(['/shop/cart'])
              //   .then(() => {
              //     window.location.reload();
              //   });
              //window.location.reload();
              this.getCart();
            }
            if(data.ResponseStatus.IsValidation){
              debugger
              this.toastrService.info(data.ResponseStatus.lstErrorsOrValidations[0].ErrorMessage);
            }
        });
        }
      }
      else{
        this.toastrService.error('Product is out of stock');
      }
      
      
    //this.productService.addToCart(product);
  }

  getCart(){
     
    this.oProductManager.getCart().pipe(map((response: any) => response), first(val => val != null)).subscribe((data: any) => {
      
      //console.log(data.cart.productCartItems.length);
      this.oMessageService.cartCount.next(data.cart.productCartItems.length);
      //this.productCartItems = data.cart.productCartItems;
      this.toastrService.success('Product Added to Cart Successfully')
    })
  }

  addToWishlist(product: any) {
    //this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    //this.productService.addToCompare(product);
  }

}
