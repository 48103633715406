import { ContactType } from '@app/shared/classes/models/commonModel/commonEnum';

export class UserAddress{
    userAddressID: number;
    userID: number;
    contactTypeID: number;
    address: string;
    address1: string;
    landmark: string;
    city: string;
    stateID: number;
    state: string;
    country: string;
    zip: string;
    contactPhone: string;
    contactEmail: string;
    mobileNo: string;
    contactName: string;
    isEdit: boolean;
    isActive: boolean;  
    isSelected: boolean;  
    
    constructor(){
        this.userAddressID = 0;
        this.userID = 0;
        this.contactTypeID = ContactType.ShippingContact;
        this.address = "";
        this.address1 = "";
        this.landmark = "";
        this.city = "";
        this.stateID = 0;
        this.state = "";
        this.country = "";
        this.zip= "";
        this.contactPhone = "";
        this.contactEmail = "";
        this.mobileNo = "";
        this.contactName = "";
        this.isEdit = false;
        this.isActive = true;
        this.isSelected = false;
    }
}