<ng-template  class="theme-modal " #orderCancelConfirmModal let-modal >
    <div class="modal-content quick-view-modal  " >
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
<div class="row mt-2">

    <div class="col-sm-12 text-center">
        <h3>Do you want to cancel this order?</h3>
    </div>
    <div class="col-sm-12 mt-2 text-center">
        <button class="btn btn-solid  mr-1" (click)="cancelOrder()" >Yes</button>
        <button class="btn btn-solid " (click)="modal.dismiss('Cross click')" >No</button>
    </div>
</div>

           
           
            
        </div>
    </div>
</ng-template>
