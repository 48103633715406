import { UserAddress } from '@app/shared/classes/models/userAddress';
import { OrderDetails } from '@app/shared/classes/models/orderDetails';
import { OrderPaymentDetails } from '@app/shared/classes/models/orderPaymentDetails';
import { OrderRefundDetails } from '@app/shared/classes/models/orderRefundDetails';
import { ProductVariantStock } from '@app/shared/classes/models/productVariantStock'

export class Order{
    orderId: number;
    totalCount: number;
    userId: number;
    userEmail: string;
    userFirstName: string;
    userLastName: string;
    userMobileNumber: string;
    orderNumber: string;
    invoiceNumber: string;
    totalOrderValue: number;
    cartId: number;
    orderStatusId: number;
    orderStatus: string;
    shippingAddressId: number;
    billingAddressId: number;
    trackingId: string;
    comments: string;
    createdBy: string;
    createdOn: string;
    updatedBy: string;
    updatedOn: string;
    billingAddress: UserAddress;
    shippingAddress: UserAddress;
    orderDetail: Array<OrderDetails>;
    orderDate: string;
    pGOrderId: string;
    paymentID: string;
    grossOrderValue: number;
    discountAmount: number;
    couponCode: string;
    shippingPartner: string;
    additionalDiscountPercent: number;
    additionalDiscountAmount: number;
    orderPaymentDetail: OrderPaymentDetails;
    OrderRefundDetail: OrderRefundDetails;
    PVSList: Array<ProductVariantStock>;
}