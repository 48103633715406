import { ProductCartItem } from '@app/shared/classes/models/productCartItem';
import { Product } from '@app/shared/classes/models/product';

export class ProductCart{
    userId: number;
    userSessionId: string;
    authToken: string;
    productCartItems: Array<ProductCartItem>;
    products: Array<Product>;

    constructor(){
        this.userId = 0;
        this.userSessionId = '';
        this.authToken = '';
        this.productCartItems = [];
        this.products = [];
    }
}