export class ProductCartItem{
    productId: number;
    productVariantId: number;
    qty: number;

    constructor(){
        this.productId = 0;
        this.productVariantId = 0;
        this.qty = 0;
    }
}