<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>{{'KNOW IT ALL FIRST!' | translate}}</h4>
								<p>{{'Never Miss Anything From Fit Tantra By Signing Up To Our Newsletter.' | translate}} </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">{{'Newsletter' | translate}}</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>{{'Categories' | translate}}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li *ngFor="let x of lstProductCategory">
									<a href="javascript:void(0)" [routerLink]="['/shop/products/productCategory/', x.ProductCategoryId]">{{ x.ProductCategoryDesc | translate}}</a>
								</li>
                                <!-- <li><a href="javascript:void(0)">{{'Women' | translate}} </a></li>
								<li><a href="javascript:void(0)">{{'Seniors' | translate}}</a></li>
                                <li><a href="javascript:void(0)">{{'Kids' | translate}} </a></li> -->
                                
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>{{'why we choose' | translate}}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">{{'shipping & return' | translate}}</a></li>
                                <li><a href="javascript:void(0)">{{'secure shopping' | translate}}</a></li>
                                <li><a href="javascript:void(0)">{{'gallary' | translate}}</a></li>
                                <li><a href="javascript:void(0)">{{'affiliates' | translate}}</a></li>
                                <li><a href="javascript:void(0)">{{'contacts' | translate}}</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>Headquaters</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>FitTantra,
						            145/A Ashokgar, 
									Kolkata-700108, West Bengal, India</li>
						        <li><i class="fa fa-phone"></i>Call Us: +91 9836803782</li>	
						        <li><i class="fa fa-envelope-o"></i>Email Us: <a>support@fittantra.com</a></li>
						        <!-- <li><i class="fa fa-fax"></i>Fax: 123456</li> -->
						    </ul>
						</div>
						<span id="siteseal">
							<script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=OB4k3e25141NtGzIlljdn9KDYBEKSQCysJVdDvInOq1ttnvi7sQNuPoKRQ1m"></script>
						  </span>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} FitTantra</p>
                  </div>
				  
                </div>
                <!-- <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div> -->
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->