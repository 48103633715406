import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpClient) { }

  apiUrl: string;

  getProductTypeCategory(): Observable<any>{
   this.apiUrl = environment.apiUrl + 'Product/GetProductTypeCategory';
   //this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/Product/GetProductTypeCategory";
   return this.http.get<any>(this.apiUrl);
 }
}
