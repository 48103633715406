import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class  NotificationService {

  config: MatSnackBarConfig = {
    duration: 3000,
    horizontalPosition: 'center',
    verticalPosition: 'bottom'
  };

    constructor(public snackBar: MatSnackBar) { }

    showSuccess(message: string): void {
        this.snackBar.open(message, '', { duration: 4000 });
    }

    showError(message: string): void {
        const config = new MatSnackBarConfig();
    config.panelClass = ['background-red'];
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
        // The second parameter is the text in the button. 
        // In the third, we send in the css class for the snack bar.
        // this.snackBar.open(message, 'X', { panelClass: ['error'], duration: 10000 });
        this.snackBar.open(message, 'x', config);
        ////console.log(message)
      // this.snackBar.open(message, 'X', { duration: 4000 });
    }

    success(message) {
      this.config.panelClass = ['notification', 'success'];
      this.snackBar.open(message, '', this.config);
    }
  
    error(message) {
      this.config.panelClass = ['notification', 'error'];
      this.snackBar.open(message, '', this.config);
    }


}
