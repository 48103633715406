import { Component, OnInit, Input, HostListener } from '@angular/core';
import { TokenStorageService } from '@app/shared/services/token-storage.service';
import { UserManager } from '@app/shared/manager/user/usermanager';
import { Router } from '@angular/router';
import { User } from '@app/shared/classes/models/user';
import { map, filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  isLoggedIn = false;
  username?: string;
  guestID: string;
  oUser: User; 
  isGuestUser: boolean;
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;

  constructor(private tokenStorageService: TokenStorageService, private oUserManager: UserManager, private router: Router) { }

  ngOnInit(): void {
    this.username = "Account";
    this.isGuestUser = true;
    this.oUser = new User();
    //debugger
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    const user = this.tokenStorageService.getUser();

    
     if (this.isLoggedIn) {
       const user = this.tokenStorageService.getUser();
       this.oUser.userId = user.UserID;       
       this.guestID = user.GuestID;
       if(this.guestID == '' || this.guestID == null){
        this.username = user.UserFirstName;
        this.isGuestUser = false;
       }
     }
  }

  Logout(){
    
    this.oUserManager.logout(this.oUser).pipe(map((response: any) => response)).subscribe((data: any) => { 
      
      ////console.log(data);
      if(data.ResponseStatus.IsSuccess){
        this.tokenStorageService.signOut();
        
        // this.router.navigateByUrl('/home/healthproducts');
        // window.location.href="yourpagedomain/login"
        //window.location.reload();

        this.router.navigate(['/home/healthproducts'])
        .then(() => {
          window.location.reload();
        });
      }
      
    });
   
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

}
