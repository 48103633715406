<ng-template class="theme-modal" #quickView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-6  col-xs-12">
                    <div class="quick-view-img">
                        <img [src]="ImageSrc ? ImageSrc : defualtVariantImgSrc" [alt]="defualtVariantImgAlt" class="img-fluid">
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2>{{ product.productName | titlecase }}</h2>
                        <h4>
                            <span *ngIf="product?.discount">{{product.discount}}% off</span>
                        </h4>
                        <h3>{{ selectedVariant.mRP * currency?.price | currency:currency?.currency:'symbol' }}
                            <del *ngIf="product?.discount"><span class="money">{{ selectedVariant.mRP * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
                        </h3>
                        
                        <!-- <ul class="color-variant" *ngIf="Color(product.variants).length">
                            <li [class]="color" *ngFor="let color of Color(product.variants)"
                                [ngStyle]="{'background-color': color}" (click)="ChangeVariants(color, product)">
                            </li>
                        </ul> -->
                        <div class="size-box" *ngIf="uniqFlavour.length">
                            <ul>
                                <li [class.active]="selectedFlavour == flavour" 
                                    *ngFor="let flavour of uniqFlavour; index as j" >
                                    <a href="javascript:void(0)" (click)="ChangeFirstVariants(flavour, product);">
                                      {{ flavour | titlecase }}
                                    </a>
                                </li>
                            </ul>
                          </div>
                        <div class="border-product">
                            <h6 class="product-title">product details</h6>
                            <p>{{ product.description.substring(0, 200)+'...' }}</p>
                        </div>
                        <div class="product-description border-product">
                            <div class="size-box" *ngIf="uniqSize.length">
                                <ul>
                                    <li [class.active]="selectedSize == x.size" 
                                        *ngFor="let x of uniqSize; index as i" >
                                        <a href="javascript:void(0)" (click)="ChangeVariants(x.size, product);">
                                          {{ x.size }} {{x.unitName}}
                                        </a>
                                    </li>
                                </ul>
                              </div>
                            <!-- <h5 class="avalibility" *ngIf="counter <= product.stock"><span>In Stock</span></h5>-->
                            <h5 class="avalibility" *ngIf="selectedVariant.inStock <= counter"><span>Out of Stock</span></h5> 
                            <h6 class="product-title">quantity</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                    <input type="text" name="quantity" class="form-control input-number"  [value]="counter" disabled>
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus" [disabled]="counter >= 5" data-type="plus" (click)="increment()">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-buttons">
                            <a href="javascript:void(0)" class="btn btn-solid" [class.disabled]="selectedVariant.inStock <= counter" (click)="addToCart(product)">add to cart</a>
                            <!-- <a href="javascript:void(0)" class="btn btn-solid" [class.disabled]="counter > product.stock" (click)="addToCart(product)">add to cart</a> -->
                            <!-- <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]" class="btn btn-solid">view detail</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>