import {ProductVariant} from '@app/shared/classes/models/productVariant';

export class VariantImage{
    productVariantId: number;
    productId: number;
    imageMasterId: number;
    imagePath: string;
    imageName: string;
    src: string;
    alt: string;
    productVariants: Array<ProductVariant>;
}