import {Product } from '@app/shared/classes/models/product';
import {ProductFlavour } from '@app/shared/classes/models/productFlavour';
import { ProductSize } from '@app/shared/classes/models/productSize';

export class ProductWrapper {
    productFlavours: Array<ProductFlavour>;
    productSizes: Array<ProductSize>;
    products: Array<Product>;

    constructor(){
        this.productFlavours = [];
        this.productSizes = [];
        this.products = [];
    }
}