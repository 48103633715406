<ng-template class="theme-modal" #guestmodal let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-6  col-xs-12 leftLogin">
                   <img  src="assets/images/G-login.png">
                </div>
                <div class="col-lg-6 rtl-text">

                    <!-- <div class="quick-view-img">
                      <input   type="radio" name="gender" (change)="resetModelValue()" [value]= false [(ngModel)]="isLoginAsGuest"> Login &nbsp; &nbsp;
                        <input   type="radio" name="gender" [value]= true (change)="resetModelValue()" [(ngModel)]="isLoginAsGuest"> Login As Guest &nbsp; &nbsp;
                    </div> -->
                    <div class="quick-view-img2">
                        <button (click)="changeTab()" [disabled]="isLoginAsGuest == false" [ngClass]="{'active': isLoginAsGuest == false}"  class="active">Login </button>
                        <button (click)="changeTab()" [disabled]="isLoginAsGuest == true" [ngClass]="{'active': isLoginAsGuest == true}" >Login As Guest </button> 
                    </div>

                    <div class="product-right">
                        <div *ngIf="!isLoginAsGuest">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input type="text" class="form-control" name="email" [(ngModel)]="oUser.userEmail" placeholder="Email" required="">
                            </div>
                            <div class="form-group">
                                <label for="review">Password</label>
                                <input type="password" class="form-control" name="review" [(ngModel)]="oUser.userPswd" placeholder="Enter your password" required="">
                            </div>
                            <div *ngIf="showMessage" class="text text-danger ">
                                {{message}}  
                            </div> 
                            <button class="btn btn-solid mt-1" (click)="login()">Login</button>
                        </div>
                        <div *ngIf="isLoginAsGuest">
                            <div class="form-group">
                                <label for="email">First Name</label>
                                <input type="text" class="form-control" name="email" [(ngModel)]="oUser.userFirstName" placeholder="First Name" required="">
                            </div>
                            <div class="form-group">
                                <label for="review">Last Name</label>
                                <input type="text" class="form-control" name="review" [(ngModel)]="oUser.userLastName" placeholder="Last Name" required="">
                            </div>
                            <div class="form-group">
                                <label for="review">Email</label>
                                <input type="text" class="form-control" name="review" [(ngModel)]="oUser.userEmail" placeholder="Email" required="">
                            </div>
                            <div class="form-group">
                                <label for="review">Mobile</label>
                                <input type="text" class="form-control" name="review" [(ngModel)]="oUser.userMobileNumber" placeholder="Mobile No." required="">
                            </div>
                            <div *ngIf="showMessage" class="text text-danger ">
                                {{message}}  
                            </div> 
                            <button class="btn btn-solid mt-1" (click)="loginAsGuest()">Login</button>
                        </div>
                        <div class="product-buttons">
                            <!-- <a href="javascript:void(0)" class="btn btn-solid" [class.disabled]="isLoggedIn == false || selectedVariant.inStock <= counter" (click)="addToCart(product)">add to cart</a> -->
                            <!-- <a href="javascript:void(0)" class="btn btn-solid" [class.disabled]="counter > product.stock" (click)="addToCart(product)">add to cart</a> -->
                            <!-- <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]" class="btn btn-solid">view detail</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



</ng-template>
