import { Injectable, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {UserService} from '@app/shared/api_services/user/user.service';
import { MenuService } from '@app/shared/api_services/menu/menu.service';
import { map, filter, switchMap, mergeMap } from 'rxjs/operators';
import { MenuComponent } from '../components/menu/menu.component';

// Menu
export class Menu {
	id?: number;
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnInit{

	constructor(private oMenuService: MenuService) {
		this.getMenuItems();
	 }

	ngOnInit(): void{
		
		
	}

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;
	public test: any;
	public MENUITEMS: Array<Menu>;
	public items: any;


	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	getMenuItems(){
		this.MENUITEMS = [
			{
				id: 0, title: 'home', type: 'link', megaMenu: false, active: false, path:'/home/healthproducts',
				
			},
			{
				id: 0, title: 'Shop', type: 'link', active: false, path: '/shop',
				
			},
			{
				id: 0, title: 'Products', type: 'sub', active: false, children: [	
				]
			},
			
			{
				id: 0, title: 'Categories', type: 'sub', active: false, children: [
					
				]
			},
			// {
			// 	id: 0, title: 'pages', type: 'sub', active: false, children: [
			// 		{
			// 			id: 0, title: 'account', type: 'sub', active: false, children: [
			// 				// { path: '/pages/wishlist', title: 'wishlist', type: 'link' },
			// 				{ id: 0, path: '/pages/cart', title: 'cart', type: 'link' },
			// 				{ id: 0, path: '/pages/dashboard', title: 'dashboard', type: 'link' },
			// 				{ id: 0, path: '/pages/login', title: 'login', type: 'link' },
			// 				{ id: 0, path: '/pages/register', title: 'register', type: 'link' },
			// 				{ id: 0, path: '/pages/contact', title: 'contact', type: 'link' },
			// 				{ id: 0, path: '/pages/forget/password', title: 'forget-password', type: 'link' },
			// 				{ id: 0, path: '/pages/profile', title: 'profile', type: 'link' },
			// 				{ id: 0, path: '/pages/checkout', title: 'checkout', type: 'link' },
			// 			]
			// 		},
			// 		// { path: '/pages/aboutus', title: 'about-us', type: 'link' },
			// 		// { path: '/pages/search', title: 'search', type: 'link' },
			// 		// { path: '/pages/typography', title: 'typography', type: 'link', badge: true, badgeText: 'new' },
			// 		// { path: '/pages/review', title: 'review', type: 'link', badge: true, badgeText: 'new' },
			// 		// { path: '/pages/order/success', title: 'order-success', type: 'link' },
			// 		// 	{ 
			// 		// 		title: 'compare', type: 'sub', active: false, children: [
			// 		// 			{ path: '/pages/compare/one', title: 'compare-1', type: 'link' },
			// 		// 			{ path: '/pages/compare/two', title: 'compare-2', type: 'link', badge: true, badgeText: 'new' }
			// 		// 		]
			// 		// 	},
			// 		{ id: 0, path: '/pages/collection', title: 'collection', type: 'link' },
			// 		// { path: '/pages/lookbook', title: 'lookbook', type: 'link' },
			// 		// { path: '/pages/404', title: '404', type: 'link' },
			// 		{ id: 0, path: '/pages/comingsoon', title: 'coming-soon', type: 'link', badge: true, badgeText: 'new' },
			// 		{ id: 0, path: '/pages/faq', title: 'faq', type: 'link' }
			// 	]
			// },
			{
				// id: 0, title: 'blogs', type: 'sub', active: false, children: [
				// 	{ id: 0, path: '/pages/blog/left/sidebar', title: 'left-sidebar', type: 'link' },
				// 	{ id: 0, path: '/pages/blog/right/sidebar', title: 'right-sidebar', type: 'link' },
				// 	{ id: 0, path: '/pages/blog/no/sidebar', title: 'no-sidebar', type: 'link' },
				// 	{ id: 0, path: '/pages/blog/details', title: 'blog-details', type: 'link' }
				// ]
			}
			
		];
		this.oMenuService.getProductTypeCategory().pipe(map((response: any) => response)).subscribe((data: any) => {
           ////console.log(data);
			
			if(data.ResponseStatus.IsSuccess){
				for(var i=0; i< this.MENUITEMS.length; i++){
					if(this.MENUITEMS[i].title == 'Products')
					{			
						for(var j=0; j < data.ProductType.length; j++){
							let objMenu = new Menu();
							objMenu.title = data.ProductType[j].ProductTypeDesc;
							objMenu.id = data.ProductType[j].ProductTypeId;
							objMenu.path = '/shop/products/productType/'+ objMenu.id;
							objMenu.type = 'link';
							this.MENUITEMS[i].children.push(objMenu)
						}
						
					}
					else if(this.MENUITEMS[i].title == 'Categories')
					{
						for(var j=0; j < data.ProductCategory.length; j++){
							let objMenu = new Menu();
							objMenu.title = data.ProductCategory[j].ProductCategoryDesc;
							objMenu.id = data.ProductCategory[j].ProductCategoryId;
							objMenu.path = '/shop/products/productCategory/'+ objMenu.id;
							objMenu.type = 'link';
							this.MENUITEMS[i].children.push(objMenu)
						}
					}
				}
			}

			
        });
	
	this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	}

	

// 	MENUITEMS: Menu[] = [
// 		{
// 			title: 'home', type: 'link', megaMenu: false, active: false, path:'/home/healthproducts',
// 			//children: [
// 				//{
// //					title: 'clothing', type: 'sub', active: false, children: [
// //						{ path: '/home/fashion', title: 'fashion-01', type: 'link' },
// //						{ path: '/home/fashion-2', title: 'fashion-02', type: 'link' },
// //						{ path: '/home/fashion-3', title: 'fashion-03', type: 'link' }
// //					]
// //				},
// //				{ path: '/home/vegetable', title: 'vegetable', type: 'sub', active: false, children: [
// //					{ path: '/home/fashion', title: 'fashion-01', type: 'link' },
// //					{ path: '/home/fashion-2', title: 'fashion-02', type: 'link' },
// //					{ path: '/home/fashion-3', title: 'fashion-03', type: 'link' }
// //				]},
// //				{ path: '/home/watch', title: 'watch', type: 'link' },
// //				{ path: '/home/furniture', title: 'furniture', type: 'link' },
// //				{ path: '/home/flower', title: 'flower', type: 'link' },
// //				{ path: '/home/beauty', title: 'beauty', type: 'link' },
// //				{ path: '/home/electronics', title: 'electronics', type: 'link' },
// //				{ path: '/home/pets', title: 'pets', type: 'link' },
// //				{ path: '/home/gym', title: 'gym', type: 'link' },
// //				{ path: '/home/tools', title: 'tools', type: 'link' },
// //				{ path: '/home/shoes', title: 'shoes', type: 'link' },
// //				{ path: '/home/bags', title: 'bags', type: 'link' },
// //				{ path: '/home/marijuana', title: 'marijuana', type: 'link' }
// 			//]
// 		},
// 		{
// 			title: 'Shop', type: 'link', active: false, path: '/shop',
// 			//children: [
// 				//{ path: '/shop/collection/left/sidebar', title: 'left-sidebar', type: 'link' },
// //				{ path: '/shop/collection/right/sidebar', title: 'right-sidebar', type: 'link' },
// //				{ path: '/shop/collection/no/sidebar', title: 'no-sidebar', type: 'link' },
// //				{ path: '/shop/collection/infinitescroll', title: 'Infinite Scroll', type: 'link' }
// 			//]
// 		},
// 		{
// 			title: 'Products', type: 'sub', active: false, children: [
// 				// {
// 				// 	title: 'sidebar', type: 'sub', active: false, children: [
// 				// 		{ path: '/shop/product/left/sidebar/trim-dress', title: 'left-sidebar', type: 'link' },
// 				// 		{ path: '/shop/product/right/sidebar/trim-dress', title: 'right-sidebar', type: 'link' },
// 				// 		{ path: '/shop/product/no/sidebar/trim-dress', title: 'no-sidebar', type: 'link' }
// 				// 	]
// 				// },
// 				// { path: '/shop/product/three/column/trim-dress', title: 'three-column', type: 'link' },
// 				// { path: '/shop/product/four/image/belted-dress', title: 'four-image', type: 'link' },
// 				// { path: '/shop/product/bundle/trim-dress', title: 'bundle-product', type: 'link' },
// 				// { path: '/shop/product/image/outside/trim-dress', title: 'image-outside', type: 'link' }

// 				{ path: '/shop/products/whey', title: 'Whey', type: 'link' },
// 				{ path: '/shop/products/gainers', title: 'Gainers', type: 'link' },
// 				{ path: '/shop/collection/no/sidebar', title: 'Immunity Boosters', type: 'link' },
// 				{ path: '/shop/collection/infinitescroll', title: 'Ayurvedic', type: 'link' }

// 			]
// 		},
// 		// {
// 		// 	title: 'Features', type: 'sub', megaMenu: false, badge: true, badgeText: 'new', active: false, children: [
// 		// 		{
// 		// 			title: 'portfolio', type: 'sub', active: false, children: [
// 		// 				{ path: '/pages/portfolio/grid/two', title: 'portfolio-grid-2', type: 'link' },
// 		// 				{ path: '/pages/portfolio/grid/three', title: 'portfolio-grid-3', type: 'link' },
// 		// 				{ path: '/pages/portfolio/grid/four', title: 'portfolio-grid-4', type: 'link' },
// 		// 				{ path: '/pages/portfolio/masonry/grid/two', title: 'mesonary-grid-2', type: 'link' },
// 		// 				{ path: '/pages/portfolio/masonry/grid/three', title: 'mesonary-grid-3', type: 'link' },
// 		// 				{ path: '/pages/portfolio/masonry/grid/four', title: 'mesonary-grid-4', type: 'link' },
// 		// 				{ path: '/pages/portfolio/masonry/full-width', title: 'mesonary-Full-Width', type: 'link' }
// 		// 			]
// 		// 		},
// 		// 		{
// 		// 			title: 'add-to-cart', type: 'sub', active: false, children: [
// 		// 				{ path: '/home/vegetable', title: 'cart-right', type: 'link' },
// 		// 				{ path: '/home/watch', title: 'cart-left', type: 'link' },
// 		// 				{ path: '/home/furniture', title: 'cart-top', type: 'link' },
// 		// 				{ path: '/home/flower', title: 'cart-bottom', type: 'link' },
// 		// 				{ path: '/home/fashion', title: 'cart-model-popup', type: 'link' }
// 		// 			]
// 		// 		},
// 		// 		{
// 		// 			title: 'theme-elements', type: 'sub', active: false, children: [
// 		// 				{ path: '/elements/theme/title', title: 'title', type: 'link' },
// 		// 				{ path: '/elements/theme/collection-banner', title: 'collection-banner', type: 'link' },
// 		// 				{ path: '/elements/theme/home-slider', title: 'home-slider', type: 'link' },
// 		// 				{ path: '/elements/theme/category', title: 'category', type: 'link' },
// 		// 				{ path: '/elements/theme/services', title: 'services', type: 'link' }
// 		// 			]
// 		// 		},
// 		// 		{
// 		// 			title: 'product-elements', type: 'sub', active: false, children: [
// 		// 				{ path: '/elements/product/slider', title: 'product-slider', type: 'link' },
// 		// 				{ path: '/elements/product/banners', title: 'banners', type: 'link' },
// 		// 				{ path: '/elements/product/tabs', title: 'product-tabs', type: 'link' },
// 		// 				{ path: '/elements/product/multi-slider', title: 'multi-slider', type: 'link' }
// 		// 			]
// 		// 		},
// 		// 		{
// 		// 			title: 'email-template', type: 'sub', active: false, children: [
// 		// 				{ path: 'http://themes.pixelstrap.com/multikart/front-end/email-order-success.html', title: 'order-success', type: 'extTabLink' },
// 		// 				{ path: 'http://themes.pixelstrap.com/multikart/front-end/email-order-success-two.html', title: 'order-success-2', type: 'extTabLink' },
// 		// 				{ path: 'http://themes.pixelstrap.com/multikart/front-end/email-template.html', title: 'email-template', type: 'extTabLink' },
// 		// 				{ path: 'http://themes.pixelstrap.com/multikart/front-end/email-template-two.html', title: 'email-template-2', type: 'extTabLink' }
// 		// 			]
// 		// 		}
// 		// 	]
// 		// },
// 		{
// 			title: 'Categories', type: 'sub', active: false, children: [
// 				// { path: '/pages/blog/left/sidebar', title: 'Men', type: 'link' },
// 				// { path: '/pages/blog/right/sidebar', title: 'Women', type: 'link' },
// 				// { path: '/pages/blog/no/sidebar', title: 'Seniors', type: 'link' },
// 				// { path: '/pages/blog/details', title: 'Kids', type: 'link' }
// 				//this.test
// 			]
// 		},
// 		{
// 			title: 'pages', type: 'sub', active: false, children: [
// 				{
// 					title: 'account', type: 'sub', active: false, children: [
// 						{ path: '/pages/wishlist', title: 'wishlist', type: 'link' },
// 						{ path: '/pages/cart', title: 'cart', type: 'link' },
// 						{ path: '/pages/dashboard', title: 'dashboard', type: 'link' },
// 						{ path: '/pages/login', title: 'login', type: 'link' },
// 						{ path: '/pages/register', title: 'register', type: 'link' },
// 						{ path: '/pages/contact', title: 'contact', type: 'link' },
// 						{ path: '/pages/forget/password', title: 'forget-password', type: 'link' },
// 						{ path: '/pages/profile', title: 'profile', type: 'link' },
// 						{ path: '/pages/checkout', title: 'checkout', type: 'link' },
// 					]
// 				},
// 				{ path: '/pages/aboutus', title: 'about-us', type: 'link' },
// 				{ path: '/pages/search', title: 'search', type: 'link' },
// 				{ path: '/pages/typography', title: 'typography', type: 'link', badge: true, badgeText: 'new' },
// 				{ path: '/pages/review', title: 'review', type: 'link', badge: true, badgeText: 'new' },
// 				{ path: '/pages/order/success', title: 'order-success', type: 'link' },
// 					{ 
// 						title: 'compare', type: 'sub', active: false, children: [
// 							{ path: '/pages/compare/one', title: 'compare-1', type: 'link' },
// 							{ path: '/pages/compare/two', title: 'compare-2', type: 'link', badge: true, badgeText: 'new' }
// 						]
// 					},
// 				{ path: '/pages/collection', title: 'collection', type: 'link' },
// 				{ path: '/pages/lookbook', title: 'lookbook', type: 'link' },
// 				{ path: '/pages/404', title: '404', type: 'link' },
// 				{ path: '/pages/comingsoon', title: 'coming-soon', type: 'link', badge: true, badgeText: 'new' },
// 				{ path: '/pages/faq', title: 'faq', type: 'link' }
// 			]
// 		},
// 		{
// 			title: 'blogs', type: 'sub', active: false, children: [
// 				{ path: '/pages/blog/left/sidebar', title: 'left-sidebar', type: 'link' },
// 				{ path: '/pages/blog/right/sidebar', title: 'right-sidebar', type: 'link' },
// 				{ path: '/pages/blog/no/sidebar', title: 'no-sidebar', type: 'link' },
// 				{ path: '/pages/blog/details', title: 'blog-details', type: 'link' }
// 			]
// 		}
// 	];

	LEFTMENUITEMS: Menu[] = [
		{
			id: 0, title: 'clothing', type: 'sub', megaMenu: true, active: false, children: [
			  {
				id: 0, title: 'mens fashion',  type: 'link', active: false, children: [
					  { id: 0, path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'top',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'bottom',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'shirts',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'bottom',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'sports wear',  type: 'link' }
				  ]
			  },
			  {
				id: 0, title: 'women fashion',  type: 'link', active: false, children: [
					  { id: 0, path: '/home/fashion', title: 'dresses',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'skirts',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'westarn wear',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'bottom',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'bottom wear',  type: 'link' }
				  ]
			  },
			]
		},
		{
			id: 0, title: 'bags', type: 'sub', active: false, children: [
			  { id: 0, path: '/home/fashion', title: 'shopper bags', type: 'link' },
			  { id: 0, path: '/home/fashion', title: 'laptop bags', type: 'link' },
			  { id: 0, path: '/home/fashion', title: 'clutches', type: 'link' },
			  {
				id: 0, path: '/home/fashion', title: 'purses', type: 'link', active: false, children: [
					  { id: 0, path: '/home/fashion', title: 'purses',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'wallets',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'leathers',  type: 'link' },
					  { id: 0, path: '/home/fashion', title: 'satchels',  type: 'link' }
				  ]
			  },
			]
		},
		{
			title: 'footwear', type: 'sub', active: false, children: [
			  { path: '/home/fashion', title: 'sport shoes', type: 'link' },
			  { path: '/home/fashion', title: 'formal shoes', type: 'link' },
			  { path: '/home/fashion', title: 'casual shoes', type: 'link' }
			]
		},
		{
			path: '/home/fashion', title: 'watches', type: 'link'
		},
		{
			title: 'Accessories', type: 'sub', active: false, children: [
			  { path: '/home/fashion', title: 'fashion jewellery', type: 'link' },
			  { path: '/home/fashion', title: 'caps and hats', type: 'link' },
			  { path: '/home/fashion', title: 'precious jewellery', type: 'link' },
			  {
				  path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'necklaces',  type: 'link' },
					  { path: '/home/fashion', title: 'earrings',  type: 'link' },
					  { path: '/home/fashion', title: 'rings & wrist wear',  type: 'link' },
					  {
						  path: '/home/fashion', title: 'more...',  type: 'link', active: false, children: [
							  { path: '/home/fashion', title: 'ties',  type: 'link' },
							  { path: '/home/fashion', title: 'cufflinks',  type: 'link' },
							  { path: '/home/fashion', title: 'pockets squares',  type: 'link' },
							  { path: '/home/fashion', title: 'helmets',  type: 'link' },
							  { path: '/home/fashion', title: 'scarves',  type: 'link' },
							  {
								  path: '/home/fashion', title: 'more...',  type: 'link', active: false, children: [
									  { path: '/home/fashion', title: 'accessory gift sets',  type: 'link' },
									  { path: '/home/fashion', title: 'travel accessories',  type: 'link' },
									  { path: '/home/fashion', title: 'phone cases',  type: 'link' }
								  ]
							  },
						]
					  }
				  ]
			  },
			]
		},
		{
			path: '/home/fashion', title: 'house of design', type: 'link'
		},
		{
			title: 'beauty & personal care', type: 'sub', active: false, children: [
			  { path: '/home/fashion', title: 'makeup', type: 'link' },
			  { path: '/home/fashion', title: 'skincare', type: 'link' },
			  { path: '/home/fashion', title: 'premium beaty', type: 'link' },
			  {
				  path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'fragrances',  type: 'link' },
					  { path: '/home/fashion', title: 'luxury beauty',  type: 'link' },
					  { path: '/home/fashion', title: 'hair care',  type: 'link' },
					  { path: '/home/fashion', title: 'tools & brushes',  type: 'link' }
				  ]
			  },
			]
		},
		{
			path: '/home/fashion', title: 'home & decor', type: 'link'
		},
		{
			path: '/home/fashion', title: 'kitchen', type: 'link'
		}
	];
	
	// Array
	//items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}
