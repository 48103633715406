import { Component, OnInit } from '@angular/core';
import { MessageService} from '@app/shared/services/message.service';

@Component({
  selector: 'app-errormessage',
  templateUrl: './errormessage.component.html',
  styleUrls: ['./errormessage.component.scss']
})
export class ErrormessageComponent implements OnInit {

  error: any;

  constructor(public messageService: MessageService) { 
    this.messageService.isLoading.subscribe((v) => {
      ////console.log(v);
      this.error = v;
  });
  }

  ngOnInit(): void {
   // ////console.log(this.loading);
  }

}
