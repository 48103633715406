import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenStorageService } from '@app/shared/services/token-storage.service';
import { ErrorService } from '@app/shared/services/error.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

    constructor(private tokenStorageService: TokenStorageService) {
       
        
     }

     authToken: string;
     sessionId: string;
     userID: number;
     user: any;

    handleError(error: HttpErrorResponse) {
        console.log(error);
        
        //////console.log(this.errorService.isLoading);
       // this.errorService.isLoading.next(true);
        return throwError(error);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
    
        Observable<HttpEvent<any>> {
         // this.errorService.isLoading.next(true);
         this.authToken = this.tokenStorageService.getToken();
         this.sessionId = this.tokenStorageService.getSessionId();
         this.user = this.tokenStorageService.getUser();
         
         if(this.authToken == null){
           this.authToken = '';
         }
         if(this.sessionId == null){
           this.sessionId = '';
         }
         if(this.user == null){
          this.userID = 0;
        }
        else{
          this.userID = this.user.UserID;
        }
            const authReq = req.clone({
                headers: new HttpHeaders({
                  'Content-Type':  'application/json',
                  'Token': this.authToken,
                  'SessionID': this.sessionId,
                  'UserID': this.userID.toString()
                })
              });
              //console.log(authReq);
            return next.handle(authReq)
            .pipe(
            
                catchError(this.handleError)
            )
    };
}
