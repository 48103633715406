import { VariantStock } from '@app/shared/classes/models/variantStock';

export class ProductVariant{
    variantID: number;
    variantDescription: string;
    variantName: string;
    variantCode: string;
    flavourID: number;
    flavourName: string;
    size: number;
    unitID: number;
    unitName: string;
    isActive: boolean;
    isDefaultVariant: boolean;
    imageMasterId: number;
    variantStocks: Array<VariantStock>;
    productVariantStockId: number;
    mRP: number;
    discount: number;       
    finalPrice: number;
    inStock: number;
    batchNumber: string;
    mfgDate: string;
    expiryDate: string;
    isSelected: boolean;
    quantity: number;
}