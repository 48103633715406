import{ Paging } from '@app/shared/classes/messageContracts/commonContracts/paging';
import{ CallerInfo } from '@app/shared/classes/messageContracts/commonContracts/callerInfo';
import { RequestAuth } from '@app/shared/classes/messageContracts/commonContracts/requestAuth'

export class BaseRequest{
    paging: Paging;
    callerInfo: CallerInfo;
    requestAuthorization: RequestAuth; 

    constructor(){
        this.paging = new Paging();
        this.callerInfo = new CallerInfo();
        this.requestAuthorization = new RequestAuth();
    }
}