import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';



@Injectable()
export class SearchService {
    public observableSearchService = new BehaviorSubject<any>("");
    constructor() {
    }
}