export enum EmailTypeEnum{
    PasswordUpdate = 1,
    UserCreate = 3,
    PasswordReset = 2,
    ChangeSecurityCode = 4,
    UrgentWarrant = 5,
    InformationChanged = 6,
    SubmitToJudge = 7
}

export enum ContactType{
    BillingContact = 1,
    ShippingContact = 2
}

export enum ActionType{
    Add = 1,
    Edit = 2,
    Delete = 3,
    Activate = 3,
    DeActivate = 4
}
export enum Gender{
    Male = 1,
    Female = 2
}