export class GymCouponDetails{
    userId: number;
    userSessionId: string;
    authToken: string;
    couponId: number;
    gymId: number;
    gymName: string;
    couponCode: string;
    discountType: number;
    discount: string;
    isActive: boolean;
    createdBy: string;
    createdOn: string
    updatedBy: string;
    updatedOn: string;
    additionalDiscountPercent: number;
}